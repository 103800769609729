import { __PROD__ } from '@/constants';
import { VetStatsApi, UserApi } from '@/api';
import { identifyUser as identifyUserForSentry } from '@/plugins/sentry';
import identifyUser from '@/plugins/logrocket';

const mapLocale = locale => {
  switch (locale) {
    case 'en':
      return 'uk';
    case 'sv':
      return 'se';
    default:
      return 'uk';
  }
};

export default {
  state: {
    user: null,
    stats: null,
    services: [],
    animalTypes: [],
    languages: [],
    roles: [],
    country: null,
    testToolsAvailable: ['development', 'staging'].includes(
      process.env.VUE_APP_ENV
    ),
    hasNotificationPermission:
      localStorage.getItem('hasNotificationPermission') === null ||
      localStorage.getItem('hasNotificationPermission') === 'true',
  },

  getters: {
    getUser: state => state.user,
    getUserId: state => state.user?.id,
  },

  mutations: {
    setUser: (state, payload) => {
      state.user = payload;
    },
    setStats: (state, payload) => {
      state.stats = payload;
    },
    setRoles: (state, payload) => {
      state.roles = payload;
    },
    setAnimalTypes: (state, payload) => {
      state.animalTypes = payload;
    },
    setServices: (state, payload) => {
      state.services = payload;
    },
    setLanguages: (state, payload) => {
      state.languages = payload;
    },
    setCountry: (state, payload) => {
      const country = { ...payload };
      if (payload?.timezone) country.timeZone = payload.timezone;
      state.country = country;
    },
    setHasNotificationPermission: (state, payload) => {
      state.hasNotificationPermission = payload;
      localStorage.setItem('hasNotificationPermission', payload);
    },
  },

  actions: {
    async fetchUser({ commit, state, dispatch }) {
      if (state.user !== null) return false;

      try {
        const { data } = await UserApi.fetchUser();

        const { user, roles, isDigitalClinicAdmin, claims, country, locale } =
          data;

        commit('setUser', user);

        commit('setCountry', country);

        const abilities = { user, ...claims };
        commit('auth/setPermissions', abilities, { root: true });

        commit('auth/setRoles', roles, {
          root: true,
        });
        commit('auth/setIsDigitalClinicAdmin', isDigitalClinicAdmin, {
          root: true,
        });

        commit('auth/setLocale', locale, {
          root: true,
        });
        localStorage.setItem('locale', mapLocale(locale));

        if (__PROD__) {
          identifyUser(user);
          identifyUserForSentry(user);
        }

        dispatch('fetchUserDetails');

        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async fetchStats({ commit }) {
      try {
        const res = await VetStatsApi.getStats();
        commit('setStats', res.data);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async fetchUserDetails({ commit, state }) {
      try {
        const { data } = await UserApi.fetchUserDetails(state.user.id);
        commit('setRoles', data.roles);
        commit('setAnimalTypes', data.animalTypes);
        commit('setServices', data.services);
        commit('setLanguages', data.languages);
        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async generateTestSchedule({ state }, payload) {
      try {
        const res = await UserApi.createTestSchedule(state.user.id, payload);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async generateTestBookedAppointment(_, payload) {
      try {
        const res = await UserApi.createTestBookedAppointment(payload);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};
