<template>
  <span class="inline-flex relative">
    <component
      :is="is"
      ref="button"
      :type="type"
      :to="to"
      :class="[baseClasses, colorClasses, sizeClasses, disabled || loading ? 'pointer-events-none opacity-75' : '']"
      :role="to ? 'link' : 'button'"
      :disabled="disabled || loading"
      @click="$emit('click')"
    >
      <slot />
      <spinner-overlay
        color="light"
        :loading="loading"
        size="lg"
      />
    </component>
  </span>
</template>

<script>
import { onMounted } from '@vue/composition-api';

export default {
  props: {
    color: {
      validator(value) {
        return [
          'primary',
          'secondary',
          'cancel',
          'light',
          'dark',
          'transparent',
          'action',
          'danger',
        ].includes(value);
      },
      type: String,
      default: 'secondary',
    },
    size: {
      validator(value) {
        return ['xs', 'sm', 'md', 'lg'].includes(value);
      },
      type: String,
      default: 'md',
    },
    to: {
      type: [Boolean, String, Object],
      default: false,
    },
    value: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    type: {
      type: String,
      validator(value) {
        return ['button', 'submit', 'reset'].includes(value);
      },
      default: 'submit',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { refs }) {
    onMounted(() => {
      if (props.focus) {
        refs.button.focus();
      }
    });
    const colorClasses = {
      primary: 'text-primary-darker hover:text-primary-darkest',
      secondary: 'text-gray-900 hover:text-gray-800',
      cancel: 'hover:text-gray-800',
      danger: 'text-red-700 hover:text-red-600',
      action: 'text-action-dark hover:text-action-darker',
      light: 'text-white hover:text-gray-200',
      dark: ' text-gray-900 hover:text-gray-800 ',
      transparent: 'text-gray-800',
    };
    const sizeClasses = {
      sm: 'text-sm ',
      md: 'text-base ',
      lg: 'text-lg ',
    };
    return {
      baseClasses:
        'w-full inline-flex items-center justify-center focus:outline-none transition ease-in-out duration-150',
      colorClasses: colorClasses[props.color],
      sizeClasses: sizeClasses[props.size],
    };
  },
  computed: {
    is() {
      return this.to ? 'router-link' : 'button';
    },
  },
};
</script>
