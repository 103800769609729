export default [
  {
    path: 'admin',
    name: 'admin',
    redirect: { name: 'schedule' },
    component: () =>
      import(/* webpackChunkName: "admin" */ '@/views/Admin.vue'),
    meta: {
      auth: true,
      admin: true,
      digitalClinicAdmin: true,
    },
    children: [
      {
        path: 'schedule-dashboard',
        name: 'schedule-dashboard',
        component: () =>
          import(
            /* webpackChunkName: "veterinarians" */ '@/views/admin/ScheduleDashboard.vue'
          ),
        meta: {
          auth: true,
          admin: true,
          digitalClinicAdmin: false,
          title: 'Schedule & slots overview',
        },
      },
      {
        path: 'veterinarians',
        name: 'veterinarians',
        component: () =>
          import(
            /* webpackChunkName: "veterinarians" */ '@/views/admin/Veterinarians.vue'
          ),
        meta: {
          auth: true,
          admin: true,
          digitalClinicAdmin: true,
          title: 'User management',
        },
      },
      {
        path: 'invite-user',
        name: 'invite-user',
        component: () =>
          import(
            /* webpackChunkName: "invite-user" */ '@/views/admin/InviteUser.vue'
          ),
        meta: {
          auth: true,
          admin: true,
          digitalClinicAdmin: true,
          digitalClinicOnly: true,
          title: 'User management > Add user',
        },
      },
      {
        path: 'schedule',
        name: 'schedule',
        component: () =>
          import(
            /* webpackChunkName: "schedule" */ '@/views/admin/Schedule.vue'
          ),
        meta: {
          auth: true,
          admin: true,
          digitalClinicAdmin: true,
          title: 'Schedule',
        },
      },
      //   {
      //       path: 'statistics',
      //       name: 'digital-clinic-statistics',
      //       component: () =>
      //         import(/* webpackChunkName: "statistics" */'@/views/admin/Statistics.vue'),
      //       meta: {
      //           auth: true,
      //           admin: true,
      //           title: 'Statistics'
      //       },
      //   },
      {
        path: 'settings',
        name: 'settings',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/views/admin/Settings.vue'
          ),
        meta: {
          auth: true,
          admin: true,
          digitalClinicAdmin: true,
          digitalClinicOnly: true,
          title: 'Settings',
        },
      },
      {
        path: 'support/tickets',
        name: 'support-tickets',
        component: () =>
          import(
            /* webpackChunkName: "support-tickets" */ '@/views/admin/SupportTickets.vue'
          ),
        meta: {
          auth: true,
          admin: true,
          title: 'Support tickets',
        },
      },
      {
        path: 'support/ticket/:id',
        name: 'support-ticket',
        component: () =>
          import(
            /* webpackChunkName: "support-ticket" */ '@/views/admin/SupportTicket.vue'
          ),
        meta: {
          auth: true,
          admin: true,
          title: 'Support ticket',
        },
      },
      {
        path: 'journal-templates',
        name: 'journal-templates',
        component: () =>
          import(
            /* webpackChunkName: "journal-templates" */ '@/views/admin/JournalTemplates.vue'
          ),
        meta: {
          auth: true,
          admin: true,
          title: 'Journal templates',
        },
      },
      {
        path: 'country-settings',
        name: 'country-settings',
        component: () =>
          import(
            /* webpackChunkName: "country-settings" */ '@/views/admin/CountrySettings.vue'
          ),
        meta: {
          auth: true,
          admin: true,
          digitalClinicAdmin: false,
          digitalClinicOnly: false,
          title: 'Country Settings',
        },
      },
      {
        path: 'releases',
        name: 'releases',
        component: () =>
          import(
            /* webpackChunkName: "releases" */ '@/views/admin/Releases.vue'
          ),
        meta: {
          auth: true,
          admin: true,
          digitalClinicAdmin: false,
          digitalClinicOnly: false,
          title: 'Releases',
        },
      },
      {
        path: 'animals',
        name: 'animals',
        component: () =>
          import(/* webpackChunkName: "animals" */ '@/views/admin/Animals.vue'),
        meta: {
          auth: true,
          admin: true,
          title: 'Animals',
        },
      },
      {
        path: 'animals/compare/:animal/:compareAnimal',
        name: 'animal-comparison',
        component: () =>
          import(
            /* webpackChunkName: "animal-comparison" */ '@/views/admin/animals/AnimalComparison.vue'
          ),
        meta: {
          auth: true,
          admin: true,
          title: 'Compare animals',
        },
      },

      {
        path: 'clinics',
        component: () =>
          import(
            /* webpackChunkName: "clinics" */ '@/views/admin/Clinics/Index.vue'
          ),
        meta: {
          auth: true,
          admin: false,
          digitalClinicAdmin: false,
          title: 'Clinics',
        },
        children: [
          {
            path: '/',
            name: 'clinics',
            component: () =>
              import(
                /* webpackChunkName: "clinics" */ '@/views/admin/Clinics/ClinicList.vue'
              ),
          },
          {
            path: 'map',
            name: 'clinic-map',
            component: () =>
              import(
                /* webpackChunkName: "clinics" */ '@/views/admin/Clinics/ClinicAdminMap.vue'
              ),
          },
          {
            path: ':id',
            name: 'clinic',
            component: () =>
              import(
                /* webpackChunkName: "clinics" */ '@/views/admin/Clinics/Clinic.vue'
              ),
          },
        ],
      },
      {
        path: 'veterinarian/:id',
        name: 'admin-account',
        component: () =>
          import(
            /* webpackChunkName: "admin-account" */ '@/views/AdminAccount.vue'
          ),
        meta: {
          auth: true,
        },
      },
      {
        path: 'book-follow-up-appointment',
        name: 'book-follow-up-appointment',
        component: () =>
          import(
            /* webpackChunkName: "admin-account" */ '@/views/admin/FollowUpAppointmentBooking.vue'
          ),
        meta: {
          auth: true,
        },
      },
    ],
  },
];
