import axios from 'axios';
import { API_URL_INTL } from '@/constants';
import store from '@/store/modules/auth-store';

axios.defaults.headers.common.Authorization = `Bearer ${store.state.token}`;

export default {
  async getLatestVersion(): Promise<string> {
    const { data } = await axios.get(`${API_URL_INTL}/api/vetpanel/version`);
    return data;
  },
  async getAllVersions(
    pageUrl: string | null
  ): Promise<Array<string | number>> {
    const endpoint = pageUrl || `${API_URL_INTL}/api/vetpanel/versions`;
    const { data } = await axios.get(endpoint);
    return data;
  },
  async upgradeVersion(type: string, message: string): Promise<any> {
    const payload = { type, message };
    const { data } = await axios.post(
      `${API_URL_INTL}/api/vetpanel/upgrade`,
      payload
    );
    return data;
  },
};
