const PRE_SCREENING = 'health_control';
const VETERINARY = 'veterinary';
const NUTRITIONAL = 'nutritional_counseling';
const BEHAVIOURAL = 'behavior_counseling';
const PRESCRIPTION = 'prescription_drugs';
const NURSE_BOOKING = 'nurse_booking';
const NURSE_CLINIC = 'nurse_clinic';
const NURSE_ADVICE = 'nurse_advice';

export {
  PRE_SCREENING,
  VETERINARY,
  NUTRITIONAL,
  BEHAVIOURAL,
  PRESCRIPTION,
  NURSE_BOOKING,
  NURSE_CLINIC,
  NURSE_ADVICE,
};
