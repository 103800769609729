// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  fetchAppointment(payload, locale, preview = false): AxiosPromise {
    return axios.get(`/api/vet/appointment/${payload}`, {
      params: {
        locale,
        preview: Number(preview),
      },
    });
  },
  fetchJournals(payload): AxiosPromise {
    return axios.get('/api/vet/appointments', { params: payload });
  },
  fetchAppointmentTypes(payload): AxiosPromise {
    return axios.get('/api/appointmenttypes', { params: payload });
  },
  fetchRecentAppointments() {
    return axios.get('/api/vet/recent-appointments');
  },
  update(appointmentId: number, payload: {}): AxiosPromise {
    return axios.put(`/api/vet/appointment/${appointmentId}`, payload);
  },
  getVideoStream(payload): AxiosPromise {
    return axios.get(`/api/appointment/video-archive/${payload}`);
  },
  fetchTestCredentials(): AxiosPromise {
    return axios.get('/api/opentok/test-credentials');
  },
  getWeightUnits(payload): AxiosPromise {
    return axios.get('/api/weight-units', { params: payload });
  },
  getStatistics(payload): AxiosPromise {
    return axios.get('/api/vet/appointment-stats', { params: payload });
  },
  setVetOnline(payload): AxiosPromise {
    return axios.post('/api/vet/vet-online', payload);
  },
  setVetOffline(payload): AxiosPromise {
    return axios.post('/api/vet/vet-offline', payload);
  },
  callNotification(appointmentId: number): AxiosPromise<{ message: string }> {
    return axios.post(`/api/appointments/${appointmentId}/notify/call`);
  },
  hangUp(appointmentId: number): AxiosPromise<{ message: string }> {
    return axios.post(`/api/appointments/${appointmentId}/notify/hang-up`);
  },
  async getImage(payload) {
    const { userId, fileName } = payload;
    // save image as base64String
    const response = await axios.get(`/api/upload/${userId}/${fileName}`, {
      responseType: 'arraybuffer',
    });
    return `data:${response.headers['content-type']};base64,${btoa(
      new Uint8Array(response.data).reduce(
        (dataOne, byte) => dataOne + String.fromCharCode(byte),
        ''
      )
    )}`;
  },
  fetchCaseTypes(countryId: number): AxiosPromise {
    return axios.get('/api/case-type-settings', { params: { countryId } });
  },
  getBookingMetaData(appointmentId: number): AxiosPromise {
    return axios.get(`/api/booking/${appointmentId}/booking-metadata`, {
      params: { withUserData: 1 },
    });
  },
  getCaseType(caseTypeId: number): AxiosPromise {
    return axios.get(`/api/case-types/${caseTypeId}`);
  },
  getApoExPrescriptionLink(appointmentId: number): AxiosPromise {
    return axios.get(
      `/api/vet/appointment/${appointmentId}/prescription/apoex-prescription-link`
    );
  },
  getApoExPrescriptionDetails(
    appointmentId: number,
    prescriptionId: string
  ): AxiosPromise {
    return axios.get(
      `/api/vet/appointment/${appointmentId}/prescription/apoex-prescription-link/${prescriptionId}`
    );
  },
};
