import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  fetchAllVets(params = {}): AxiosPromise {
    return axios.get(`/schedule/vets`, { params });
  },
  fetchVet(id): AxiosPromise {
    return axios.get(`/schedule/vets/${id}`);
  },
};
