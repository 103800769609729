/* eslint-disable import/no-cycle */
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  getSettings(countryId): AxiosPromise {
    return axios.get(`api/country-settings/${countryId}`);
  },
  getHolidays(countryId = null): AxiosPromise {
    return axios.get(`api/holidays/${countryId}`);
  },
  getPricing(countryId: number | undefined): AxiosPromise {
    return axios.get(`api/country-pricing/${countryId}`);
  },
};
