import { AbilityBuilder } from '@casl/ability';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

const updateAbility = claims => {
  const { can, rules } = new AbilityBuilder();

  if (claims) {
    const { roles } = claims;

    if (roles) {
      if (roles.Admin) {
        can('manage', 'Veterinarian');
        can('manage', 'Questions');
        can('manage', 'All');
      }
      if (roles.Veterinary) {
        can('manage', 'Veterinarian');
      }
      if (roles.ReferralAgent) {
        can('manage', 'ClinicListing');
      }
      if (roles.QuestionManager) {
        can('manage', 'Questions');
      }
    }
  }

  store.getters['auth/ability'].update(rules);
};

export default updateAbility;
