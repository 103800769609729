import { API_URL_INTL, API_URL_SV, API_URL_US } from '@/constants';

const getPlatform = () => {
  const platform = localStorage.getItem('platform');
  if (!platform) {
    return 'intl';
  }
  return platform;
};

const getDefaultApi = () => {
  switch (getPlatform()) {
    case 'sv':
      return API_URL_SV;
    case 'us':
      return API_URL_US;
    default:
      return API_URL_INTL;
  }
};

export { getDefaultApi, getPlatform };
