// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  getCodes(payload): AxiosPromise {
    return axios.get('api/diagnosis-codes', { params: payload });
  },
  submitFeedback(payload): AxiosPromise {
    return axios.post('api/diagnosis-codes/feedback', payload);
  },
};
