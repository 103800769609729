/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["element"] }] */

const fadeTransitions = {
  methods: {
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
  },
};

const buttonProps = {
  render: () => {},

  props: {
    to: {
      required: false,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      return this.to ? 'router-link' : 'button';
    },
  },
};

export { fadeTransitions, buttonProps };
