<template>
  <div class="inline-flex w-full">
    <span class="relative block w-full">
      <div
        v-if="disabled"
        class="pointer-events-none absolute inset-0 select-none bg-white opacity-50"
      />
      <button
        v-show="!isOpen || !searchable"
        ref="selectButton"
        type="button"
        aria-roledescription="Dropdown button"
        tabindex="1"
        class="flex w-full items-center justify-between space-x-4 text-left"
        :class="[
          baseClasses,
          borderClasses,
          focusClasses,
          hoverClasses,
          isOpen ? 'border-azure-25 bg-gray-100 text-gray-600' : '',
          disabled ? 'cursor-default' : '',
        ]"
        :disabled="loading || disabled"
        @click="toggleOpen"
        @keydown.esc="isOpen = false"
      >
        <span :class="[labelSizes]">{{
          selectedItem ? selectedItem[labelAttr] : placeholder
        }}</span>
        <fv-icon class="text-gray-600" icon="chevron-down" />
      </button>
      <input
        v-show="isOpen && searchable"
        ref="searchInput"
        v-model="searchStr"
        v-bind="$attrs"
        placeholder="Search.."
        tabindex="1"
        type="search"
        :name="name"
        class="z-50 flex w-full items-center justify-between text-left"
        :class="[
          baseClasses,
          borderClasses,
          focusClasses,
          hoverClasses,
          labelSizes,
          isOpen ? 'border-azure-25 bg-gray-100 text-gray-600' : '',
        ]"
        @keydown.esc="isOpen = false"
      />
      <transition
        enter-class="transition ease-out duration-100"
        enter-active-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-class="transition ease-in duration-75"
        leave-active-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      />
      <div class="relative">
        <div
          v-show="isOpen"
          class="absolute top-0 right-0 z-50 w-full overflow-auto rounded border bg-white py-2 shadow-sm"
          :style="{ maxHeight: height }"
        >
          <ul
            v-show="filterOptions.length"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
            class="flex flex-col"
          >
            <li
              v-for="(opt, i) in filterOptions"
              :key="i"
              @keydown.esc="isOpen = false"
            >
              <button
                type="button"
                :tabindex="i + 1"
                class="focus:outline-none w-full py-2 px-2 text-left hover:bg-gray-100 focus:bg-gray-100"
                :value="opt[valueAttr]"
                :class="[
                  opt[valueAttr] === value ? 'font-semibold text-gray-900' : '',
                ]"
                @click="selectItem(opt[valueAttr])"
              >
                <span :class="[textSizes]">{{ opt[labelAttr] }}</span>
              </button>
            </li>
          </ul>
          <p
            v-if="!filterOptions.length"
            class="py-2 px-2 text-left text-gray-700"
            :class="[textSizes]"
          >
            No options matched your search query.
          </p>
        </div>
      </div>
      <spinner-overlay color="light" :loading="loading" />
    </span>
    <div v-if="isOpen" class="absolute inset-0 z-10" @click="isOpen = false" />
  </div>
</template>

<script>
import { computed, nextTick, ref, defineComponent } from '@vue/composition-api';

export default defineComponent({
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
    valueAttr: {
      type: [String],
      default: 'id',
    },
    labelAttr: {
      type: [String],
      default: 'name',
    },
    placeholder: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '50vh',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      validator(value) {
        return ['sm', 'md', 'lg'].includes(value);
      },
      default: 'md',
    },
    labelSize: {
      type: String,
      validator(value) {
        return ['sm', 'md', 'lg'].includes(value);
      },
      default: 'md',
    },
  },
  setup(props, { emit, refs }) {
    const baseClasses =
      'bg-white text-gray-800 rounded px-2 py-2 appearance-none';
    const borderClasses = 'border border-gray-400';
    const focusClasses = 'focus:outline-none focus:border-azure-25';
    const hoverClasses = 'hover:border-fv-gray-border-darker';

    const textSizes = {
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
    };

    const labelSizes = {
      sm: 'text-base md:text-sm',
      md: 'text-base',
      lg: 'text-base md:text-lg',
    };

    const searchStr = ref('');
    const isOpen = ref(false);

    const toggleOpen = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        nextTick(() => {
          const { searchInput } = refs;
          if (searchInput) searchInput.focus();
        });
      }
    };

    const selectedItem = computed(() => {
      return props.options.find(
        option => option[props.valueAttr] === props.value
      );
    });
    const filterOptions = computed(() => {
      if (searchStr.value) {
        return props.options.filter(option => {
          return option[props.labelAttr]
            .toLowerCase()
            .includes(searchStr.value.toLowerCase());
        });
      }
      return props.options;
    });

    const selectedLabel = computed(() => {
      if (props.value && props.options.length) {
        return props.options.find(
          option => option[props.valueAttr] === props.value
        )[props.labelAttr];
      }
      return props.placeholder;
    });

    const selectItem = (value) => {
      emit('change', value);
      isOpen.value = false;
      searchStr.value = '';
      nextTick(() => {
        refs.selectButton.focus();
      });
    };

    return {
      textSizes: textSizes[props.size],
      labelSizes: labelSizes[props.labelSize],
      baseClasses,
      focusClasses,
      hoverClasses,
      borderClasses,
      selectedItem,
      searchStr,
      filterOptions,
      selectItem,
      toggleOpen,
      selectedLabel,
      isOpen,
    };
  },
});
</script>
