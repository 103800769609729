<template>
  <transition name="fade">
    <div
      v-if="loading"
      :class="[baseClasses, overlayColorClasses]"
    >
      <div
        :class="[textColorClasses]"
        class="inline-flex flex-col space-y-4 items-center justify-center"
      >
        <base-spinner
          :size="size"
          :class="[sizeClasses]"
        />
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      validator(value) {
        return ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(value);
      },
      type: String,
      default: 'md',
    },
    color: {
      validator(value) {
        return ['light', 'dark', 'transparent'].includes(value);
      },
      type: String,
      default: 'light',
    },
  },
  setup(props) {
    const overlayColorClasses = {
      light: 'bg-beigeLight bg-opacity-25',
      dark: 'bg-smoke-lightest',
      transparent: 'bg-transparent',
    };
    const textColorClasses = {
      light: 'text-gray-900',
      dark: 'text-white',
      transparent: 'text-gray-700',
    };
    const sizeClasses = {
      sm: 'text-base',
      md: 'text-lg',
      lg: 'text-xl',
      xl: 'text-3xl',
      xxl: 'text-5xl',
    };
    return {
      baseClasses:
        'absolute inset-0 flex flex-col justify-center items-center transition ease-in-out duration-150 z-40 pointer-events-none',
      textColorClasses: textColorClasses[props.color],
      overlayColorClasses: overlayColorClasses[props.color],
      sizeClasses: sizeClasses[props.size],
    };
  },
});
</script>
