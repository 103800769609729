import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  // This function should be implemented carefully. It makes a request to the Quinyx API,
  // and maps retrieved schedule to our database, removing previous rows.
  fetchSchedules({ fromDate, toDate, countryId }): AxiosPromise {
    return axios.get('schedule/quinyx', {
      params: {
        fromDate,
        toDate,
        countryId,
      },
    });
  },
};
