// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  fetchContactOptions(locale: string): AxiosPromise {
    return axios.get('/api/referral/contact-options', { params: { locale } });
  },
  sendReferral(payload): AxiosPromise {
    return axios.post('/api/referral/send', payload);
  },
  fetchAllJournalComments(id: number): AxiosPromise {
    return axios.get(`/api/animal/get/${id}/comments`);
  },
};
