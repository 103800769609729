import { AnimalsApi, InsuranceCompanyApi } from '@/api';

export default {
  state: {
    animalList: {
      data: [],
    },
    animal: {},
    animalLoading: false,
    animalHistory: {},
    animalHistoryLoading: false,
    insurances: [],
    compareAnimal: {},
    compareAnimalLoading: false,
    compareAnimalHistory: {},
    compareAnimalHistoryLoading: false,
    compareInsurances: [],
  },
  mutations: {
    setAnimalList: (state, payload) => {
      state.animalList = payload;
    },
    setAnimal: (state, { animalKey, data }) => {
      state[animalKey] = data;
    },
    setAnimalLoading: (state, { animalKey, loading }) => {
      state[`${animalKey}Loading`] = loading;
    },
    setAnimalHistory: (state, { animalKey, data }) => {
      state[`${animalKey}History`] = data;
    },
    setAnimalHistoryLoading: (state, { animalKey, loading }) => {
      state[`${animalKey}HistoryLoading`] = loading;
    },
    setInsurances: (state, { insurancesKey, data }) => {
      state[insurancesKey] = data;
    },
  },
  actions: {
    fetchAnimals({ commit }, payload) {
      return AnimalsApi.fetchAnimals(payload)
        .then(res => {
          commit('setAnimalList', res.data);
          return res;
        })
        .catch(e => {
          Promise.reject(e);
        });
    },
    clearAnimals({ commit }) {
      commit('setAnimalList', []);
    },
    clearAnimalsForCompare({ commit }) {
      commit('setAnimal', { animalKey: 'animal', data: {} });
      commit('setAnimal', { animalKey: 'compareAnimal', data: {} });
    },
    async fetchAnimalData(
      { commit, dispatch },
      { id, animalKey, insurancesKey, locale }
    ) {
      commit('setAnimalLoading', { animalKey, loading: true });

      AnimalsApi.fetchAnimalWithRelations(id, locale)
        .then(({ data }) => {
          commit('setAnimal', {
            animalKey,
            data,
          });

          if (insurancesKey) {
            // eslint-disable-next-line camelcase
            InsuranceCompanyApi.getAllByCountryId(data.user?.country_id).then(
              res => {
                commit('setInsurances', {
                  insurancesKey,
                  data: res.data.data || res.data.insurance,
                });
              }
            );
          }
        })
        .finally(() => {
          commit('setAnimalLoading', { animalKey, loading: false });
        });

      dispatch('fetchAnimalHistory', { id, animalKey });
    },
    async fetchAnimalHistory({ commit }, { id, animalKey }) {
      commit('setAnimalHistoryLoading', { animalKey, loading: true });

      const { data } = await AnimalsApi.fetchAnimalHistory(id);

      commit('setAnimalHistory', {
        animalKey,
        data,
      });

      commit('setAnimalHistoryLoading', { animalKey, loading: false });
    },
  },
};
