// import { RemoteBookingApi } from '@/api';

import { CountryApi, PartnerApi, RemoteBookingApi } from '@/api';

export const VETPANEL_FOLLOWUP_FLOW = 'vetpanel_followup_flow';
export const TWENTY_TWO_EIGHTY_EIGHT_BOOKING_FLOW = '2288_booking_flow';
export const TRIAGE_BOOKING_FLOW = 'triage_booking_flow';

export default {
  state: {
    previousAppointmentId: null,
    selectedService: null,
    selectedAppointment: null,
    selectedAnimal: null,
    selectedUser: null,
    selectedPartner: null,
    userAnimalList: [],
    lookupAnimalList: [],
    userInput: {
      ssn: '',
      countryCode: '46',
      mobilePhone: '',
    },
    countryCodes: [],
    policyValidated: false,
    singleTimezone: '',
    initialBookingDescription: null,
  },

  getters: {
    getSelectedUser: state => state.selectedUser,
    getSelectedAnimal: state => state.selectedAnimal,
    getSelectedService: state => state.selectedService,
    getSelectedAppointment: state => state.selectedAppointment,
    getPreviousAppointmentId: state => state.previousAppointmentId,
    getSingleTimezone: state => state.singleTimezone,
    getMobilePhoneFormatted: state =>
      `${state.userInput.countryCode}${parseInt(state.userInput.mobilePhone)}`,
    getUserInputMobilePhone: state => state.userInput.mobilePhone,
    getUserInputCountryCode: state => state.userInput.countryCode,
    getInitialBookingDescription: state => state.initialBookingDescription,
  },

  mutations: {
    setSingleTimezone: (state, timezone) => {
      state.singleTimezone = timezone;
    },
    setSelectedService: (state, payload) => {
      state.selectedService = payload;
    },
    setSelectedUser: (state, payload) => {
      state.selectedUser = payload;
    },
    setSelectedAnimal: (state, payload) => {
      state.selectedAnimal = payload;
    },
    setSelectedAppointment: (state, payload) => {
      state.selectedAppointment = payload;
    },
    setPreviousAppointmentId: (state, payload) => {
      state.previousAppointmentId = payload;
    },
    setUserAnimalList: (state, list) => {
      state.userAnimalList = list;
    },
    setLookupAnimalList: (state, list) => {
      state.lookupAnimalList = list;
    },
    setSelectedPartner: (state, partner) => {
      state.selectedPartner = partner;
    },
    setUserInputSsn: (state, ssn) => {
      state.userInput.ssn = ssn;
    },
    setUserInputCountryCode: (state, countryCode) => {
      state.userInput.countryCode = countryCode;
    },
    setUserInputMobilePhone: (state, mobilePhone) => {
      state.userInput.mobilePhone = mobilePhone;
    },
    setPolicyValidated: (state, value) => {
      state.policyValidated = value;
    },
    setCountryCodes: (state, countryCodes) => {
      state.countryCodes = countryCodes;
    },
    setInitialBookingDescription: (state, initialBookingDescription) => {
      state.initialBookingDescription = initialBookingDescription;
    },

    clearState: state => {
      state.selectedService = null;
      state.selectedAppointment = null;
      state.selectedAnimal = null;
      state.selectedUser = null;
      state.previousAppointment = null;
      state.selectedPartner = null;
      state.userAnimalList = [];
      state.lookupAnimalList = [];
      state.initialBookingDescription = null;
      state.userInput = {
        ssn: '',
        countryCode: '',
        mobilePhone: '',
      };
    },
  },

  actions: {
    async fetchCountryCodes({ commit }) {
      const { data } = await CountryApi.fetchCountryCodes();
      commit('setCountryCodes', data.country_codes);
      return data.country_codes;
    },

    async fetchUserByAttributes({ commit, dispatch, state, getters }) {
      try {
        const { userInput } = state;
        const { ssn } = userInput;

        const mobilePhone = getters.getMobilePhoneFormatted;

        commit('setSelectedUser', null);
        const { data } = await PartnerApi.fetchUserByAttributes(
          ssn,
          mobilePhone
        );
        if (data.data) {
          commit('setSelectedUser', data.data);
          dispatch('fetchUserAnimals');
        }
        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async fetchUserAnimals({ state, commit }) {
      try {
        if (!state.selectedUser) {
          return;
        }

        const userId = state.selectedUser.id;
        const { data } = await PartnerApi.fetchAnimalsForUser(userId);

        if (data?.data?.length) {
          console.log('Setting user animal list to: ', data.data);
          commit('setUserAnimalList', data.data);
        }
        return data.data;
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async lookupPolicy({ commit, state }) {
      commit('setPolicyValidated', false);

      const { userInput } = state;
      const { ssn, mobilePhone } = userInput;
      const partnerId = state.selectedPartner.id;

      try {
        const { data } = await PartnerApi.lookupData(partnerId, {
          ssn,
          mobilePhone,
        });

        if (!data.animals) {
          commit('setPolicyValidated', false);
          return;
        }
        commit('setPolicyValidated', true);
        commit('setLookupAnimalList', data.animals);

        return data;
      } catch (error) {
        commit('setPolicyValidated', false);
        console.error(error);
        return Promise.reject(error);
      }
    },

    async sendAppointmentConfirmationLink({ state }, mobilePhone) {
      const { selectedAppointment } = state;
      const { appointmentId } = selectedAppointment;
      if (!selectedAppointment || !appointmentId) {
        throw new Error('Missing appointment');
      }
      const { data } = await RemoteBookingApi.sendAppointmentConfirmationLink(
        appointmentId,
        mobilePhone
      );

      return data;
    },

    async createUserByAttributes({ commit, state }, countryId) {
      const { userInput } = state;

      const { data } = await RemoteBookingApi.createUserByAttributes(
        userInput.countryCode,
        userInput.mobilePhone,
        userInput.ssn,
        countryId
      );
      commit('setSelectedUser', data.data);
      return data.data;
    },

    async fetchUser({ commit }, userId) {
      const { data } = await RemoteBookingApi.fetchUser(userId);
      commit('setSelectedUser', data.data);
      return data.data;
    },

    async addAnimalToUser(
      { commit, state },
      { animalName, animalType, animalGender, animalBirthdate }
    ) {
      const { selectedUser } = state;

      const { data } = await RemoteBookingApi.addAnimalToUser(
        selectedUser.id,
        animalName,
        animalType,
        animalGender,
        animalBirthdate
      );
      commit('setSelectedAnimal', data.data);
      return data.data;
    },

    async updateAnimal({ commit, state }, payload) {
      const animal = state.selectedAnimal;

      const { data } = await RemoteBookingApi.updateAnimal(animal.id, payload);

      commit('setSelectedAnimal', data.data);
      return data.data;
    },

    async fetchAnimal({ commit }, { animalId, userId }) {
      const { data } = await RemoteBookingApi.fetchAnimal(animalId, userId);

      commit('setSelectedAnimal', data.data);

      return data.data;
    },
  },
};
