import { ReferralApi } from '@/api';

const referral = {
  clinic: null,

  email: '',
  reason: '',

  referralContactId: null,
  sendAllJournals: false,
  selectedImages: [],
  selectedComments: [],
};

export default {
  state: {
    referral,
    contactOptions: [],
    allJournalComments: [],
  },

  getters: {
    getContactOptions: state => state.contactOptions,
    getReferral: state => state.referral,
  },

  mutations: {
    setContactOptions: (state, payload) => {
      state.contactOptions = payload;
    },
    setAllJournalComments: (state, payload) => {
      state.allJournalComments = payload;
    },
    setReferral: (state, payload) => {
      state.referral = payload;
    },
  },

  actions: {
    async sendReferral(_, payload) {
      try {
        const res = await ReferralApi.sendReferral(payload);
        console.log('This is the sent referral', res.data.mailer);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async fetchContactOptions({ commit, rootState }) {
      const { locale } = rootState;
      try {
        const res = await ReferralApi.fetchContactOptions(locale);
        commit('setContactOptions', res.data.options);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    async fetchAllJournalComments({ commit }, id) {
      try {
        const res = await ReferralApi.fetchAllJournalComments(id);
        commit('setAllJournalComments', res.data.appointments_with_comments);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    clearReferral({ commit }) {
      commit('setReferral', { ...referral });
    },
  },
};
