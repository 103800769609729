<template>
  <dd class="mt-1  leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
    <slot />
  </dd>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
