<template>
  <textarea 
    :value="value"
    :rows="rows" 
    :placeholder="placeholder"
    class="border border-gray-300 rounded w-full p-4 text-gray-900 leading-7
     transition ease-in-out duration-150 
    focus:outline-none focus:ring-1 focus:ring-beige"
    @input="$emit('input', $event.target.value)"  
  />
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      required: false,
      default: 4,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: String,
      default: () => '',
    },
    id: {
      type: String,
      default: '',
    },
  },
};
</script>
