<template>
  <fv-icon
    :icon="icon"
    class="animate-spin object-contain align-middle"
    :size="size"
  />
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'md',
    },
    isGray: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      if (this.isGray) {
        return 'GraySpinner';
      } else {
        return 'spinner';
      }
    },
  },
};
</script>
