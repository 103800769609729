export default [
  {
    path: '',
    name: 'call-triage-dashboard',
    component: () =>
      import(
        /* webpackChunkName: "vetpanel" */ '@/views/call-triage/Index.vue'
      ),
    meta: {
      auth: true,
      isFolksamTriageNurse: true,
    },
  },
  {
    path: 'journey',
    name: 'call-triage-journey',
    component: () => import('@/views/call-triage/Triage.vue'),
    meta: {
      auth: true,
      isFolksamTriageNurse: true,
    },
  },
  {
    path: 'recommendations',
    name: 'call-triage-recommendations',
    component: () =>
      import(
        /* webpackChunkName: "vetpanel" */ '@/views/call-triage/Recommendations.vue'
      ),
    meta: {
      auth: true,
      isFolksamTriageNurse: true,
    },
    children: [],
  },
  {
    path: 'summary',
    name: 'call-triage-summary',
    component: () =>
      import(
        /* webpackChunkName: "vetpanel" */ '@/views/call-triage/Summary.vue'
      ),
    meta: {
      auth: true,
      isFolksamTriageNurse: true,
    },
    children: [],
  },
];
