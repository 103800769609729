import LogRocket from 'logrocket';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';
import { __PROD__ } from '@/constants';

const privateFieldNames = ['token', 'password'];

const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup([
  ...privateFieldNames,
]);

if (__PROD__) {
  LogRocket.init('7bbsdu/firstvet-vetpanel-eu', {
    network: {
      requestSanitizer: request => {
        const sanitizedRequest = { ...request };
        sanitizedRequest.headers.Authorization = '*';
        return requestSanitizer(sanitizedRequest);
      },
      responseSanitizer,
    },
    // disable sending information for call-triage routes
    shouldSendData: () => window.location?.href?.search('call-triage') === -1,
  });
}

export default function identifyUser(user) {
  LogRocket.identify(user.id, {
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
  });
}
