<template>
  <div class="overflow-auto py-2 px-4 pt-16 lg:px-8 lg:pt-16">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
