<template>
  <div class="pt-1 pb-0.5 flex-row space-x-1">
    <base-label
      v-if="labelText.length"
      color="dark"
    >
      {{ labelText }}
    </base-label>
    <span
      v-if="!isInnerContentVisible && displayValue"
      class="text-navy"
    >{{ displayValue }}</span>

    <span
      v-if="isInnerContentVisible"
      class="text-navy"
    >
      <slot /> 
    </span>

    <span
      v-if="error"
      class="text-red-800 font-semibold"
    >{{ error }}</span>
  </div>
</template>

<script>
export default {
  props: {
    showValidationErrors: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    isInnerContentVisible: {
      type: Boolean,
      default: true,
    },
    labelText: {
      type: String,
      default: '',
    },
    isLabelNeverInline: {
      type: Boolean,
      default: false,
    },
    displayValue: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>
