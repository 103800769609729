import { DigitalClinicsApi } from '@/api';

function removeSeconds(time) {
  return time.slice(0, -3);
}

const digitalClinic = {
  id: '',
  name: '',
  description: '',
  email: '',
  slug: '',
  logo: '',
  country_id: '',
  country: {
    currency_id: '',
  },
  work_day_start: '',
  work_day_end: '',
  price_normal: '',
  price_inconvenient: '',
  price_holiday: '',
  currency: {},
};

export default {
  state: {
    digitalClinic: { ...digitalClinic },
    digitalClinics: [],
    digitalClinicUsers: [],
    digitalClinicUser: {},
  },

  getters: {
    getDigitalClinic: state => state.digitalClinic,
    getDigitalClinicId: state => state.digitalClinic.id,
    getDigitalClinicTimezone: state => {
      return state.digitalClinic && state.digitalClinic.country
        ? state.digitalClinic.country.timezone
        : '';
    },
    getDigitalClinics: state => state.digitalClinics,
    getDigitalClinicUsers: state => state.digitalClinicUsers,
    getDigitalClinicUser: state => state.digitalClinicUser,
  },

  mutations: {
    setDigitalClinic: (state, payload) => {
      state.digitalClinic = payload;
      if (state.digitalClinic.work_day_start.length !== 5) {
        state.digitalClinic.work_day_start = removeSeconds(
          state.digitalClinic.work_day_start
        );
      }
      if (state.digitalClinic.work_day_end.length !== 5) {
        state.digitalClinic.work_day_end = removeSeconds(
          state.digitalClinic.work_day_end
        );
      }
    },
    setDigitalClinics: (state, payload) => {
      state.digitalClinics = payload;
    },
    setDigitalClinicUsers: (state, payload) => {
      state.digitalClinicUsers = payload;
    },
    setDigitalClinicUser: (state, payload) => {
      state.digitalClinicUser = payload;
    },
    updateDigitalClinics: (state, payload) => {
      const clinicIndex = state.digitalClinics.findIndex(
        clinic => clinic.id === payload.id
      );
      state.digitalClinics[clinicIndex] = payload;
    },
  },

  actions: {
    update({ commit, state, dispatch }, payload) {
      return DigitalClinicsApi.update(payload)
        .then(res => {
          console.log(res.data);
          commit('updateDigitalClinics', res.data);
          dispatch('setDigitalClinic', { ...state.digitalClinic, ...res.data });
          return res;
        })
        .catch(e => {
          console.log('error', e);
          return Promise.reject(e);
        });
    },

    fetchClinics({ commit, state, dispatch }, userId) {
      return DigitalClinicsApi.fetchClinics(userId)
        .then(res => {
          commit('setDigitalClinics', res.data);

          const currentDigitalClinicId =
            state.digitalClinic && state.digitalClinic.id;

          if (
            userId &&
            res.data.length &&
            (!currentDigitalClinicId ||
              !res.data.some(d => d.id === currentDigitalClinicId))
          ) {
            dispatch('setDigitalClinic', res.data[0]);
            dispatch('fetchPrices', res.data[0].id);
          }

          return res;
        })
        .catch(e => {
          console.log('error', e);
          return Promise.reject(e);
        });
    },

    fetchClinic({ commit, dispatch }, idOrSlug) {
      return DigitalClinicsApi.fetchClinic(idOrSlug)
        .then(res => {
          if (res && res.data && res.data.id) {
            commit('setDigitalClinic', res.data);
            commit('updateDigitalClinics', res.data);
            dispatch('fetchPrices', res.data.id);
          }
        })
        .catch(e => {
          console.log('error', e);
          return Promise.reject(e);
        });
    },

    fetchPrices({ commit, state, dispatch }, payload) {
      return DigitalClinicsApi.fetchPrices(payload)
        .then(res => {
          if (res.data && res.data.id) {
            const prices = {
              price_normal: res.data.price_normal,
              price_inconvenient: res.data.price_inconvenient,
              price_holiday: res.data.price_holiday,
              currency: res.data.currency,
            };
            dispatch('setDigitalClinic', { ...state.digitalClinic, ...prices });
            commit('updateDigitalClinics', state.digitalClinic);
          }

          return res;
        })
        .catch(e => {
          console.log('error', e);
          return Promise.reject(e);
        });
    },

    updatePrices({ commit, state, dispatch }, payload) {
      return DigitalClinicsApi.updatePrices(payload)
        .then(res => {
          const prices = {
            price_normal: res.data.price_normal,
            price_inconvenient: res.data.price_inconvenient,
            price_holiday: res.data.price_holiday,
            currency: res.data.currency,
          };
          dispatch('setDigitalClinic', { ...state.digitalClinic, ...prices });
          commit('updateDigitalClinics', state.digitalClinic);

          return res;
        })
        .catch(e => {
          console.log('error', e);
          return Promise.reject(e);
        });
    },

    fetchUsersForClinic({ commit, state }) {
      return DigitalClinicsApi.fetchUsersForClinic(state.digitalClinic.id)
        .then(res => {
          commit('setDigitalClinicUsers', res.data);
          return res.data;
        })
        .catch(e => {
          console.log('error', e);
          return Promise.reject(e);
        });
    },

    fetchClinicUser({ commit }, payload) {
      return DigitalClinicsApi.fetchClinicUser(payload)
        .then(res => {
          commit('setDigitalClinicUser', res.data);
          return res.data;
        })
        .catch(e => {
          console.log('error', e);
          return Promise.reject(e);
        });
    },

    clearDigitalClinic({ commit }) {
      commit('setDigitalClinic', { ...digitalClinic });
    },

    setDigitalClinic({ commit, dispatch }, payload) {
      dispatch('clearDigitalClinic');
      commit('setDigitalClinic', payload);

      // Update selected country based on digital clinic's country
      commit('admin/setCountry', payload.country.id, { root: true });

      // Reset selected filters for schedule since we don't use them for digital clinics
      commit('admin/schedule/setVet', null, { root: true });
      commit('admin/schedule/setService', 1, { root: true });
    },

    setDigitalClinicById({ state, dispatch }, payload) {
      if (state.digitalClinics && state.digitalClinics.length) {
        const dClinic = state.digitalClinics.find(d => d.id === +payload);

        if (dClinic) {
          dispatch('setDigitalClinic', dClinic);
          dispatch('fetchPrices', dClinic.id);
        } else {
          dispatch('clearDigitalClinic');
        }
      }
    },
  },
};
