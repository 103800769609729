import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  getConversation(id: number, locale: string): AxiosPromise {
    return axios.get(`/api/chat/conversation/${id}`, {
      params: { locale },
    });
  },

  // "Closes" conversation, sets open to false and removes it from vet's inbox, the appointment is finished
  closeConversation(id: number): AxiosPromise {
    return axios.put(`/api/chat/conversation/${id}`, {
      open: 'false',
    });
  },

  // Sets all participants to read only
  endConversation(id: number): AxiosPromise {
    return axios.delete(`/api/chat/conversation/${id}`);
  },

  // Sets the vet to a participant in the chat, "claims" the chat
  assignVeterinarian(id: number): AxiosPromise {
    return axios.put(`/api/chat/conversation/${id}/user`);
  },

  getQueueSignature(): AxiosPromise {
    return axios.get(`api/chat/queue-signature`);
  },

  getUserSignature(vetId: null | number = null): AxiosPromise {
    if (vetId) {
      return axios.get(`api/chat/user-signature/${vetId}`);
    }
    return axios.get(`api/chat/user-signature`);
  },

  sendSystemMessage(appointmentId: number, message: string): AxiosPromise {
    return axios.post(`api/chat/conversation/${appointmentId}/system-message`, {
      message,
    });
  },
};
