<template>
  <dt class="leading-5 font-medium text-gray-600">
    <slot />
  </dt>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
