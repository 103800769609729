// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  fetchSchedule(params): AxiosPromise {
    return axios.get(`/schedule`, { params });
  },
  fetchBookedSlots(params): AxiosPromise {
    return axios.get(`slots/booked`, { params });
  },
  fetchAvailableSlots(params): AxiosPromise {
    return axios.get(`slots/available`, { params });
  },
  fetchScheduleVets(params): AxiosPromise {
    return axios.get(`/schedule/vets`, { params });
  },
  fetchScheduledVets(params): AxiosPromise {
    return axios.get(`/schedule/scheduled-vets`, { params });
  },
  fetchScheduleServices(params): AxiosPromise {
    return axios.get(`/api/services`, { params });
  },
  fetchServiceInfo(id, params): AxiosPromise {
    return axios.get(`/api/service/${id}`, { params });
  },
  addShift(body): AxiosPromise {
    return axios.post(`/availability-schedule`, body);
  },
  editShift(id, body): AxiosPromise {
    return axios.put(`/availability-schedule/${id}`, body);
  },
  deleteShift(id): AxiosPromise {
    return axios.delete(`/availability-schedule/${id}`);
  },
  fetchBooking(bookingId): AxiosPromise {
    return axios.get(`/slots/booked/${bookingId}`);
  },
  updateBooking(bookingId, params): AxiosPromise {
    return axios.put(`/slots/booked/${bookingId}`, { ...params });
  },
};
