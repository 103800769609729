<template>
  <div
    class="py-5 sm:grid sm:grid-cols-3 sm:gap-4"
    :class="[bgClasses]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    bg: {
      type: String,
      validator(value) {
        return ['gray', 'white', 'transparent'].includes(value);
      },
      default: 'transparent',
    },
  },
  setup(props) {
    const bgClasses = {
      gray: 'bg-gray-100',
      white: 'bg-white',
      transparent: 'bg-transparent',
    };
    return {
      bgClasses: bgClasses[props.bg],
    };
  },
};
</script>
