<template>
  <div>
    <p v-if="errorMsg" class="text-center text-red-800">
      {{ errorMsg }}
    </p>
    <email-password-login v-if="legacyLogin" />

    <base-button
      v-else
      color="primary"
      class="my-4 w-full"
      :to="firstvetAuthUrl"
      :href="firstvetAuthUrl"
      data-testid="login-button"
    >
      Sign in
    </base-button>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { FIRSTVET_AUTH_URL, BASE_URL } from '@/constants';

import { defineComponent } from '@vue/composition-api';
import Echo from '@/plugins/echo';
import EmailPasswordLogin from './EmailPasswordLogin.vue';

export default defineComponent({
  components: { EmailPasswordLogin },
  props: {
    legacyLogin: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      uiState: 'idle',
      message: '',
    };
  },
  computed: {
    firstvetAuthUrl() {
      return `${FIRSTVET_AUTH_URL}/se/?platform=vetpanel&redirect_url=${BASE_URL}/sv`;
    },
  },
  mounted() {
    this.setPlatform('sv');
    this.$axios.defaults.baseURL = process.env.VUE_APP_API_URL_SV;
    Echo.connector.pusher.config.authEndpoint = `${process.env.VUE_APP_API_URL_SV}/broadcasting/auth`;

    if (this.uiState === 'idle') {
      if (document.getElementById('email')) {
        document.getElementById('email').focus();
      }
    }
  },
  methods: {
    ...mapActions('auth', ['setAuthData', 'handleLoginRoutePush']),
    ...mapActions('user', ['fetchUser']),
    ...mapMutations('user', ['setUser']),
    ...mapMutations('auth', ['setRoles', 'setLocale']),
    ...mapActions(['setPlatform']),
  },
});
</script>
