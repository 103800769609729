import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  fetchAll(payload, locale): AxiosPromise {
    return axios.get('/api/vet/orders', { params: { ...payload, locale } });
  },
  fetchMore(url, locale): AxiosPromise {
    const endpoint = url.split('api')[1];
    return axios.get(`/api${endpoint}`, { params: { locale } });
  },
  query(payload, locale): AxiosPromise {
    return axios.get('/api/vet/orders', { params: { ...payload, locale } });
  },
};
