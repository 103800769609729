<template>
  <input
    id="1"
    type="checkbox"
    name="1"
    class="form-switch-checkbox"
  >
</template>

<script>
export default {};
</script>

<style lang="postcss">
</style>
