// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  update(payload): AxiosPromise {
    return axios.put('/api/journal/update', payload);
  },
  finish(payload): AxiosPromise {
    return axios.post('/api/journal/finish', payload);
  },
  fetchReferralTypes(locale: string): AxiosPromise {
    return axios.get('/api/referral-types', { params: { locale } });
  },
  sendJournalComment(payload): AxiosPromise {
    return axios.post('/api/journal/add/comment', payload);
  },
  editJournalComment(payload): AxiosPromise {
    return axios.put(`/api/journal/edit/comment/${payload.id}`, payload.data);
  },
  fetchPrescription(id): AxiosPromise {
    return axios.get(`/api/v2/journals/${id}/prescription`);
  },
  updateOrCreatePrescription(id, payload): AxiosPromise {
    return axios.put(`/api/v2/journals/${id}/prescription`, payload);
  },
  async fetchUnsignedPrescriptions(): AxiosPromise {
    const {data} =  await axios.get(`/api/v2/journals/unsigned-prescriptions`);
    return data;
  },
  lockUnsignedPrescriptions(id: number): AxiosPromise {
    return axios.put(`/api/v2/journals/prescriptions/${id}/lock`);
  },
};
