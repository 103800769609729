/* eslint-disable camelcase */
import CountrySettingsApi from '@/api/modules/admin/country-settings';
import consultations from './consultations-store';
import schedule from './schedule-store';
import veterinarians from './veterinarians-store';
import slots from './slots-store';
import digitalClinic from './digital-clinic-store';
import animals from './animals-store';

export default {
  state: {
    countryId: localStorage.getItem('selectedCountry')
      ? +localStorage.getItem('selectedCountry')
      : 826,
    countryHolidays: {},
    countrySettings: {},
    countryPricing: {},
  },
  mutations: {
    setCountry(state, countryId) {
      localStorage.setItem('selectedCountry', countryId);
      state.countryId = +countryId;
    },
    setCountryHolidays(state, countryHolidays) {
      state.countryHolidays = countryHolidays;
    },
    setCountrySettings(state, countrySettings) {
      state.countrySettings = countrySettings;
    },
    setCountryPricing(state, countryPricing) {
      state.countryPricing = countryPricing;
    },
  },
  actions: {
    async fetchCountrySettings({ commit, state }) {
      const { data } = await CountrySettingsApi.getSettings(state.countryId);
      commit('setCountrySettings', data);
      return data;
    },
    async fetchCountryHolidays({ commit, state }) {
      const { data } = await CountrySettingsApi.getHolidays(state.countryId);
      commit('setCountryHolidays', data);
      return data;
    },
    async fetchCountryPricing({ commit, state }) {
      const { data } = await CountrySettingsApi.getPricing(state.countryId);
      commit('setCountryPricing', data?.standard_price ?? null);
      return data;
    },
  },
  getters: {
    country(state, __, rootState) {
      return rootState.country?.countries.find(
        country => country.id === state.countryId
      );
    },
    countryTimezone(state, getters) {
      const selectedCountry = getters.country;
      return selectedCountry
        ? selectedCountry.timeZone || selectedCountry.timezone
        : 'Europe/London';
    },
  },
  modules: {
    consultations: {
      namespaced: true,
      ...consultations,
    },
    schedule: {
      namespaced: true,
      ...schedule,
    },
    slots: {
      namespaced: true,
      ...slots,
    },
    veterinarians: {
      namespaced: true,
      ...veterinarians,
    },
    'digital-clinic': {
      namespaced: true,
      ...digitalClinic,
    },
    animals: {
      namespaced: true,
      ...animals,
    },
  },
};
