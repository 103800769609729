<template>
  <div class="relative">
    <select
      class="w-full bg-smoke-lightest appearance-none px-2 py-2 rounded border border-transparent
      cursor-pointer hover:text-black hover:border-gray-700 focus:outline-none focus:ring"
      @change="$emit('change', $event.target.value)"
    >
      <option
        v-for="option in options"
        :key="option.id"
        :selected="value === option[valueAttribute] ? true : false"
        :value="option[valueAttribute]"
      >
        {{ option[labelAttribute] }}
      </option>
    </select>
    <div class="absolute top-0 bottom-0 right-0 flex flex-col justify-center items-center mr-2">
      <font-awesome-icon
        icon="caret-down"
        class="text-xs text-ou-dark"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Object, Boolean],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    labelAttribute: {
      type: String,
      required: true,
    },
    valueAttribute: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
