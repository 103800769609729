import Login from '@/views/Login.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import NotFound from '@/views/NotFound.vue';

import routesVetpanel from './routes-vetpanel';
import routesAdmin from './routes-admin';
import externalLinks from './external-links';
import routesCallTriage from './routes-call-triage';

export default [
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPassword,

    meta: {
      guest: true,
    },
  },
  {
    path: '/:platform?',
    name: 'login',
    component: Login,

    meta: {
      guest: true,
    },
  },
  {
    path: '/:platform?/vetpanel',
    component: () =>
      import(/* webpackChunkName: "vetpanel" */ '@/views/Vetpanel.vue'),
    meta: {
      auth: true,
    },
    children: [...routesVetpanel, ...routesAdmin, ...externalLinks],
  },
  {
    path: '/:platform/call-triage',
    component: () =>
      import(
        /* webpackChunkName: "vetpanel" */ '@/views/call-triage/LayoutBase.vue'
      ),
    meta: {
      auth: true,
      isFolksamTriageNurse: true,
    },
    children: [...routesCallTriage],
  },
  {
    path: '*',
    name: 'error',
    component: NotFound,
    meta: {
      guest: false,
    },
  },
];
