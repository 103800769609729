import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  fetchCountryCodes(): AxiosPromise {
    return axios.get('/api/country-codes');
  },
  fetchCountries(): AxiosPromise {
    return axios.get('/schedule/countries');
  },
  fetchUsStates(): AxiosPromise {
    return axios.get('/api/us-states');
  },
  fetchLanguages(): AxiosPromise {
    return axios.get('/api/languages');
  },
  fetchTimeZones(): AxiosPromise {
    return axios.get('api/vet/timezones');
  },
};
