<template>
  <base-modal
    :show="show"
    :show-close-button="false"
    @close="$emit('close')"
  >
    <h2
      id="twoFactorTitle"
      class="mb-4 pb-2 border-b text-xl font-black"
    >
      Two factor authentication
    </h2>
    <transition name="fade">
      <form
        v-if="!showCodeField"
        class="flex flex-wrap py-2"
        @submit.prevent="submitTwoFactor"
      >
        <div class="w-full flex space-x-2 items-center">
          <h4 class="">
            The code will be sent to
          </h4>
          <div
            v-if="countryCodeList.length"
            class="font-bold p-2  bg-gray-100 border rounded"
          >
            +{{ countryCodeList.find(code => code.id == countryCode).code }} {{ phoneNumber }}
          </div>
        </div>
        <div
          class="flex justify-end space-x-2 w-full mt-4"
        >
          <base-button
            color="cancel"
            type="button"
            @click.prevent.native="$emit('close')"
          >
            Cancel
          </base-button>
          <base-button
            focus
            type="submit"
            color="primary"
            :loading="loading"
          >
            Send code
          </base-button>
        </div>
      </form>
      <form
        v-else
        class="flex flex-wrap py-2"
        @submit.prevent="submitCode"
      >
        <div class="flex w-full justify-between">
          <base-label
            for="code"
            class="flex-1"
          >
            Enter the 6-digit code sent to your phone number.
          </base-label>
          <text-button
            class="mb-2"
            :disabled="loading"
            type="button"
            @click.prevent.native="submitTwoFactor"
          >
            Re-send code
          </text-button>
        </div>
        <base-input
          id="code"
          v-model="code"
          v-focus
          placeholder="Enter code"
        />
        <p
          v-if="authError"
          class="text-red mt-2"
        >
          {{ authError }}
        </p>
        <div
          class="flex justify-end space-x-2 w-full mt-4"
        >
          <base-button
            color="cancel"
            type="button"
            @click.prevent.native="$emit('close')"
          >
            Cancel
          </base-button>
          <base-button
            type="submit"
            color="primary"
            :loading="loading"
          >
            Submit
          </base-button>
        </div>
      </form>
    </transition>
  </base-modal>
</template>

<script>
import { mapState } from 'vuex';
import BaseModal from '@/UI/modals/base-modal';
import { authMethods, authComputed } from '@/store/auth-helpers';

export default {
  components: {
    BaseModal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
    countryCode: {
      type: Number,
      required: true,
    },
    tempToken: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,

      showCodeField: false,

      code: '',

      authError: '',
    };
  },

  computed: {
    ...authComputed,
    ...mapState('country', ['countryCodes']),
    countryCodeList() {
      return this.countryCodes.map(code => {
        return {
          id: code.id,
          name: code.country,
          code: code.code,
        };
      });
    },
  },
  methods: {
    ...authMethods,

    submitTwoFactor() {
      this.loading = true;
      this.loginTwoFactor({
        token: this.tempToken,
        phone_nr: this.phoneNumber,
        country_code_id: this.countryCode,
      })
        .then(() => {
          this.showCodeField = true;
        })
        .catch(e => {
          this.loading = false;
          this.showCodeField = false;
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitCode() {
      if (this.code.length < 6 || this.code.length > 6) {
        this.$notify({
          group: 'error',
          title: 'Error',
          text: 'The code needs to be 6 digits',
        });
        return;
      }
      this.loading = true;
      this.submitCodeTwoFactor({ code: this.code, token: this.tempToken })
        .then(() => this.setAuthData(this.tempToken))
        .then(() => {
          this.handleLoginRoutePush();

          this.$notify({
            group: 'auth',
            title: 'Signed in',
            text: `Welcome back!`,
          });
        })
        .catch(e => {
          this.authError = e.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
