// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  saveSupportTicket(payload: any): AxiosPromise {
    return axios.post('/api/vet/support/ticket', payload);
  },
  getSupportTickets(pageUrl: string | null, payload: any): AxiosPromise {
    const endpoint = pageUrl || '/api/vet/support/tickets';
    return axios.get(endpoint, { params: payload });
  },
  getSupportTicket(id): AxiosPromise {
    return axios.get(`/api/vet/support/ticket/${id}`);
  },
  updateSupportTicket(id: number, data: any): AxiosPromise {
    return axios.put(`/api/vet/support/ticket/${id}`, data);
  },
  sendAnswer(id: number, data: any): AxiosPromise {
    return axios.post(`/api/vet/support/ticket/${id}/answer`, data);
  },
  getSupportTicketCategories(payload): AxiosPromise {
    return axios.get('api/vet/support/tickets/categories', { params: payload });
  },
  updateAnswer(ticketId: number, answerId: number, data: any): AxiosPromise {
    return axios.put(
      `/api/vet/support/ticket/${ticketId}/answer/${answerId}`,
      data
    );
  },
};
