import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  getProducts({ body, userId }): AxiosPromise {
    return axios.post(
      `${process.env.VUE_APP_API_URL_SHOP_SV}/rest/search/vet`,
      body,
      {
        headers: {
          'User-Id': `vet-se-${userId}`,
        },
      }
    );
  },
  getProductRecommendations({
    journalId,
    countryId,
  }: {
    journalId: number;
    countryId: number;
  }) {
    return axios.get(
      `${process.env.VUE_APP_API_URL_SHOP_SV}/rest/product-recommendations`,
      { params: { journalId, countryId } }
    );
  },
  addProductRecommendation({
    productId,
    journalId,
    countryId,
  }: {
    productId: number;
    journalId: number;
    countryId: number;
  }): AxiosPromise {
    return axios.put(
      `${process.env.VUE_APP_API_URL_SHOP_SV}/rest/product-recommendations`,
      { productIds: [productId], journalId, countryId }
    );
  },
  removeProductRecommendation({
    productId,
    journalId,
    countryId,
  }: {
    productId: number;
    journalId: number;
    countryId: number;
  }): AxiosPromise {
    return axios.delete(
      `${process.env.VUE_APP_API_URL_SHOP_SV}/rest/product-recommendations`,
      { data: { productIds: [productId], journalId, countryId } }
    );
  },
};
