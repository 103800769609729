import Vue from 'vue';
import VueI18n from 'vue-i18n';
import dkTranslations from './lang/dk';
import fiTranslations from './lang/fi';
import noTranslations from './lang/no';
import ukTranslations from './lang/uk';
import seTranslations from './lang/se';
import deTranslations from './lang/de';

Vue.use(VueI18n);

export const messages = {
  dk: Object.assign(dkTranslations),
  fi: Object.assign(fiTranslations),
  no: Object.assign(noTranslations),
  uk: Object.assign(ukTranslations),
  se: Object.assign(seTranslations),
  de: Object.assign(deTranslations),
};

export const translations = {
  uk: ukTranslations,
  dk: dkTranslations,
  fi: fiTranslations,
  no: noTranslations,
  se: seTranslations,
  de: deTranslations,
};

export function makei18n(locale) {
  return new VueI18n({
    locale: locale || 'uk',
    fallbackLocale: 'uk',
    messages,
  });
}

export const i18n = makei18n(localStorage.getItem('locale'));
