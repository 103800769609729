import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  fetchAnimals(payload): AxiosPromise {
    const endpoint = payload.pageUrl
      ? payload.pageUrl
      : '/api/admin/animals/list';
    return axios.get(endpoint, { params: payload.data });
  },

  fetchAnimalWithRelations(id: number, locale: string): AxiosPromise {
    return axios.get(`/api/admin/animals/${id}`, { params: { locale } });
  },

  fetchAnimalHistory(id: number): AxiosPromise {
    return axios.get(`/api/admin/animals/${id}/history`);
  },

  updateAnimal(animal: any): AxiosPromise {
    return axios.put(`/api/admin/animals/${animal.id}`, animal);
  },

  shareAnimal(id: number, userId: number): AxiosPromise {
    return axios.post(`/api/admin/animals/${id}/share/${userId}`);
  },

  transferAnimal(
    id: number,
    userId: number,
    transferHistory: boolean
  ): AxiosPromise {
    return axios.post(`/api/admin/animals/${id}/transfer/${userId}`, {
      transfer_history: transferHistory,
    });
  },

  deleteAnimal(id: number): AxiosPromise {
    return axios.delete(`/api/admin/animals/${id}`);
  },

  transferAppointment(
    id: number,
    userId: number,
    animalId: number
  ): AxiosPromise {
    return axios.post(`/api/admin/animals/transfer-appointment`, {
      appointment_id: id,
      user_id: userId,
      animal_id: animalId,
    });
  },

  transferQuestion(id: number, userId: number, animalId: number): AxiosPromise {
    return axios.post(`/api/admin/animals/transfer-question`, {
      question_id: id,
      user_id: userId,
      animal_id: animalId,
    });
  },

  transferVoiceCall(
    id: number,
    userId: number,
    animalId: number
  ): AxiosPromise {
    return axios.post(`/api/admin/animals/transfer-voicecall`, {
      voicecall_id: id,
      user_id: userId,
      animal_id: animalId,
    });
  },
};
