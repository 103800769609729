/*eslint valid-typeof: "error"*/

const setCookie = ({
  name,
  value,
  domain,
  path = '/',
  maxAge,
}: {
  name: string;
  value: string;
  path?: string;
  domain?: string;
  maxAge?: number;
}) => {
  document.cookie = `${name}=${value}; Path=${path}${
    domain ? `; Domain=${domain}` : ''
  }${typeof maxAge !== undefined ? `; Max-Age=${maxAge}` : ''}`;
};

const getCookie = (key: string) => {
  const cookies = document.cookie;
  const name = `${key}=`;
  const ca = cookies.split(';');

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
  }

  return '';
};

export { setCookie, getCookie };
