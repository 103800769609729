import { ScheduleApi } from '@/api';

const DEFAULT_SLOT_SIZE = 15;

export default {
  state: {
    serviceId: 1,
    services: [],
    vets: [],
    vetId: null,
    fromDate: null,
    toDate: null,
  },
  mutations: {
    setService(state, serviceId) {
      state.serviceId = serviceId;
    },
    setServices(state, services) {
      state.services = services;
    },
    setVets(state, vets) {
      state.vets = vets;
    },
    setVet(state, vetId) {
      state.vetId = vetId;
    },
    setDates(state, { fromDate, toDate }) {
      state.fromDate = fromDate;
      state.toDate = toDate;
    },
  },
  actions: {
    async fetchShifts({ getters, state }) {
      const { data } = await ScheduleApi.fetchSchedule({
        fromDate: state.fromDate,
        toDate: state.toDate,
        countryId: getters.countryId,
        serviceId: state.serviceId,
        vetId: state.vetId,
        digitalClinicId: getters.digitalClinicId,
      }).catch(e => {
        return Promise.reject(e);
      });

      return data;
    },
    async fetchServices({ commit, getters }) {
      const { data } = await ScheduleApi.fetchScheduleServices({
        countryId: getters.countryId,
      }).catch(e => {
        return Promise.reject(e);
      });
      commit('setServices', data.services);
      return data.services;
    },
    async fetchVets({ commit, getters }, params = {}) {
      const { data } = await ScheduleApi.fetchScheduleVets({
        ...params,
        countryId: getters.countryId,
      }).catch(e => {
        return Promise.reject(e);
      });
      const vets = [
        {
          id: null,
          display_name: 'All veterinarians',
        },
        ...data,
      ];
      commit('setVets', []);
      commit('setVets', vets);
      return data;
    },
    async fetchScheduledVets({ state, getters }, params = {}) {
      const { data } = await ScheduleApi.fetchScheduledVets({
        fromDate: state.fromDate,
        toDate: state.toDate,
        countryId: getters.countryId,
        serviceId: state.serviceId,
        vetId: state.vetId,
        digitalClinicId: getters.digitalClinicId,
        ...params,
      }).catch(e => {
        return Promise.reject(e);
      });
      return data;
    },
    async fetchBookedSlots({ getters, state }) {
      const { fromDate, toDate } = state;
      const { data } = await ScheduleApi.fetchBookedSlots({
        fromDate,
        toDate,
        countryId: getters.countryId,
        serviceId: state.serviceId,
        vetId: state.vetId,
        digitalClinicId: getters.digitalClinicId,
      }).catch(e => {
        return Promise.reject(e);
      });
      return data;
    },
  },
  getters: {
    countryId(state, getters, rootState) {
      return rootState.admin.countryId;
    },
    slotSize(state) {
      const service =
        state.services && state.services.length
          ? state.services.find(s => s.id === +state.serviceId)
          : false;
      return service ? service.slot_size : DEFAULT_SLOT_SIZE;
    },
    digitalClinicId(state, getters, rootState) {
      return rootState.admin['digital-clinic'].digitalClinic.id;
    },
    datesAreSet(state) {
      return !!state.fromDate && !!state.toDate;
    },
  },
};
