// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  fetchQuestion(id): AxiosPromise {
    return axios.get(`/api/vet/question/${id}`);
  },
  fetchQuestions(payload): AxiosPromise {
    return axios.get('/api/vet/questions', { params: payload });
  },
  queryQuestions(payload): AxiosPromise {
    return axios.get('/api/vet/questions', { params: payload });
  },
  fetchMore(url): AxiosPromise {
    const endpoint = url.split('api')[1];
    return axios.get(`/api${endpoint}`);
  },
  update(payload): AxiosPromise {
    return axios.put(`/api/vet/question/${payload.id}`, { ...payload });
  },
};
