import { ScheduleApi } from '@/api';

export default {
  state: {
    slots: [],
    loading: false,
  },
  getters: {
    countryId(_, __, rootState) {
      return rootState.admin.countryId;
    },
    timezone(_, __, ___, rootGetters) {
      return rootGetters['admin/countryTimezone'];
    },
    dates(state, getters, rootState) {
      const { fromDate, toDate } = rootState.admin.schedule;
      return { fromDate, toDate };
    },
    serviceId(state, getters, rootState) {
      const { serviceId } = rootState.admin.schedule;
      return serviceId;
    },
    vetId(state, getters, rootState) {
      const { vetId } = rootState.admin.schedule;
      return vetId;
    },
    params(state, getters) {
      return {
        ...getters.dates,
        countryId: getters.countryId,
        timezone: getters.timezone,
        serviceId: getters.serviceId,
        vetId: getters.vetId,
      };
    },
  },
  mutations: {
    setSlots(state, slots) {
      state.slots = slots;
    },
    toggleLoading(state, loadState) {
      state.loading = loadState;
    },
  },
  actions: {
    async fetchAvailableSlots({ commit, getters }) {
      commit('setSlots', []);
      commit('toggleLoading', true);
      const { data } = await ScheduleApi.fetchAvailableSlots({
        ...getters.params,
        fromTime: '00:00:00',
        toTime: '00:00:00',
        countAll: 1,
        hideUserData: 1,
      }).catch(e => {
        commit('toggleLoading', false);
        throw e;
      });
      if (!data) {
        throw Error('Error in fetching slots');
      }
      commit('toggleLoading', false);
      commit('setSlots', data);
      return data;
    },
  },
};
