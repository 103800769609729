import { debounce } from 'lodash';
import { ClinicsApi } from '@/api';

const clinicMap = {
  namespaced: true,
  state: {
    filterCurrentlyOpen: false,
    filterOnCall: false,
    filterAnimals: [],
    filterClinicType: [],
    filterCountry: null,
    filterCountryCoordinates: {
      lat: 63,
      lng: 17,
    },
    clinics: [],
    filteredClinics: [],
    activeMarkerId: 0,
    isOpen: false,
    selectedClinic: null,
    referralClinic: null,
    clinicIsLoading: false,
    loading: false,

    clinicsInView: [],
  },

  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
    setCountry(state, payload) {
      state.filterCountry = payload;
    },
    setFilterCountryCoordinates(state, payload) {
      state.filterCountryCoordinates = payload;
    },
    setClinic(state, clinic) {
      state.selectedClinic = clinic;
    },
    setReferralClinic(state, clinic) {
      state.referralClinic = clinic;
    },
    setClinicIsLoading(state, payload) {
      state.clinicIsLoading = payload;
    },
    setClinics(state, payload) {
      state.clinics = Object.freeze(payload);
    },
    setFilteredClinics(state, payload) {
      state.filteredClinics =
        //   Object.freeze(
        payload.map(m => {
          return {
            ...m,
            position: {
              lat: +m.lat,
              lng: +m.lng,
            },
            title: m.name,
          };
        });
      //   );
    },
    addFilter(state, payload) {
      if (Object.prototype.hasOwnProperty.call(state, payload.filterType)) {
        state[payload.filterType].push(payload.id);
      }
    },
    removeFilter(state, payload) {
      if (Object.prototype.hasOwnProperty.call(state, payload.filterType)) {
        state[payload.filterType] = state[payload.filterType].filter(f => {
          return f !== payload.id;
        });
      }
    },
    setFilterOnCall(state, payload) {
      state.filterOnCall = payload;
    },
    setFilterCurrentlyOpen(state, payload) {
      state.filterCurrentlyOpen = payload;
    },
    removeAllFilters(state, payload) {
      if (payload.filterType === 'filterClinicType') {
        state[payload.filterType] = [];
      } else if (payload.filterType === 'filterAnimals') {
        state[payload.filterType] = [];
      }
    },
    setActiveMarkerId(state, id) {
      state.activeMarkerId = id;
    },
    displayClinicMap(state, isOpen) {
      // Reset filters on reopen
      if (isOpen) {
        state.filterCurrentlyOpen = false;
        state.filterOnCall = false;
        state.filterAnimals = [];
        state.filterClinicType = [];
      }

      state.isOpen = isOpen;
    },
    setClinicsInView(state, clinics) {
      state.clinicsInView = clinics;
    },
  },

  getters: {
    isClinicTypeFilterApplied(state) {
      return state.filterClinicType.length > 0;
    },

    isAnimalTypeFilterApplied(state) {
      return state.filterAnimals.length > 0;
    },
    getCountry(state, getters, rootState) {
      const { countries } = rootState.clinicStore;

      if (!countries || !countries.length) {
        return null;
      }

      return countries.find(c => {
        return c.id === state.filterCountry;
      });
    },
  },

  actions: {
    async getClinics({ state, commit }, params = {}) {
      commit('setLoading', true);
      try {
        const clinics = await ClinicsApi.filterClinics({
          country: state.filterCountry,
          ...params,
        });
        // commit('setFilteredClinics', clinics);

        const result = clinics.map(m => {
          return {
            ...m,
            position: { lat: +m.lat, lng: +m.lng },
            title: m.name,
          };
        });

        commit('setLoading', false);
        return result;
      } catch (error) {
        commit('setLoading', false);
        throw Error(error);
      }
    },
    async getClinic({ commit }, id) {
      commit('setActiveMarkerId', id);
      commit('setClinicIsLoading', true);
      const { data } = await ClinicsApi.getClinic(id);
      console.log(data);
      commit('setClinicIsLoading', false);
      commit('setClinic', data);
      commit('setReferralClinic', data);
    },
    async getClinicDetails({ commit }, id) {
      commit('setClinicIsLoading', true);
      const { data } = await ClinicsApi.getClinic(id);
      commit('setClinicIsLoading', false);
      return data;
    },
    toggleFilter({ state, commit }, filter) {
      if (filter.id === 'currentlyOpen') {
        commit('setFilterCurrentlyOpen', filter.checked);
      } else if (filter.id === 'onCall') {
        commit('setFilterOnCall', filter.checked);
      } else {
        const checkedFilters = state[filter.filterType];
        const filterExists = checkedFilters.includes(filter.id);

        if (!!filter.checked && !filterExists) {
          commit('addFilter', filter);
        } else if (!filter.checked && filterExists) {
          commit('removeFilter', filter);
        }
      }
      //   dispatch('debounceFilterClinics');
    },
    debounceFilterClinics: debounce(({ dispatch }) => {
      dispatch('filterClinics');
    }, 700),
    async filterClinics({ state, commit }) {
      const {
        filterAnimals,
        filterClinicType,
        filterOnCall,
        filterCurrentlyOpen,
        filterCountry,
      } = state;

      commit('setLoading', true);
      try {
        const res = await ClinicsApi.filterClinics({
          clinicTypes: filterClinicType,
          animalTypes: filterAnimals,
          currentlyOpen: filterCurrentlyOpen ? 1 : null,
          onCall: filterOnCall ? 1 : null,
          country: filterCountry,
        });
        if (res && res.data && res.data.clinics) {
          //   commit('setFilteredClinics', res.data.clinics);
          commit('setLoading', false);
          return res.data;
        }
        commit('setLoading', false);
        return null;
      } catch (error) {
        commit('setLoading', false);
        throw Error(error);
      }
    },
    setCountry({ commit, getters }, payload) {
      commit('setCountry', payload);

      const country = getters.getCountry;

      if (!country || !country.coordinates) {
        return;
      }

      commit('setFilterCountryCoordinates', country.coordinates);
    },
  },
};

export default clinicMap;
