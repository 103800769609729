<template>
  <div class="flex p-8">
    <h1 class="text-center p-8">
      404<br>Page not found
    </h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="postcss">
</style>
