<template>
  <div class="w-full">
    <input
      :id="inputId"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :type="type"
      :class="border ? 'border' : 'border-0'"
      class="focus:outline-none w-full rounded border-gray-400 border-opacity-50 py-2 px-2 leading-tight focus:border-gray-600"
      :name="name"
      v-bind="$attrs"
      :data-testid="inputId"
      @input="$emit('input', $event.target.value)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <spinner-overlay color="light" :loading="loading" />
  </div>
</template>

<script>
import toCamelCase from 'lodash/camelCase';

export default {
  props: {
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    border: {
      type: Boolean,
      default: true,
      required: false,
    },
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  setup() {
    const baseClassesArray = [
      'border-gray-400',
      'border-opacity-50',
      'py-2',
      'px-2',
      'leading-tight',
      'rounded',
      'focus:outline-none',
      'focus:border-gray-600',
      'w-full',
    ];

    const inputClassList = baseClassesArray.join(' ');

    return {
      inputClassList,
    };
  },
  computed: {
    inputId: {
      get() {
        const elementName = this.name ? this.name : '';
        const id = this.id.trim() !== '' ? this.id : toCamelCase(elementName);
        return id;
      },
    },
  },
};
</script>
