// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  update(payload): AxiosPromise {
    // Using POST with method PUT as query param because of bug:
    // https://laravel.io/forum/02-13-2014-i-can-not-get-inputs-from-a-putpatch-request
    return axios({
      method: 'post',
      url: `/api/digital-clinics/${payload.id}?_method=PUT`,
      data: payload.clinicData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  // fetchClinics(userId) {
  //   return axios.get(
  //     `/api/digital-clinics`,
  //     userId
  //       ? {
  //           params: {
  //             user: userId,
  //             admin: 1,
  //           },
  //         }
  //       : null
  //   );
  // },

  fetchPrices(payload) {
    return axios.get(`/api/digital-clinics/${payload}/prices`);
  },
  updatePrices(payload) {
    return axios.put(
      `/api/digital-clinics/${payload.id}/prices`,
      payload.priceData
    );
  },

  /* User management */
  checkUserExists(payload) {
    return axios.post(
      `/api/digital-clinics/${payload.digitalClinicId}/users/unique`,
      payload.data
    );
  },

  createUser(payload) {
    return axios.post(
      `/api/digital-clinics/${payload.digitalClinicId}/users`,
      payload.data
    );
  },

  updateUser(payload) {
    return axios.put(
      `/api/digital-clinics/${payload.digitalClinicId}/users/${payload.userId}`,
      payload.data
    );
  },

  removeUser(payload) {
    return axios.put(
      `/api/digital-clinics/${payload.digitalClinicId}/users/${payload.userId}/remove`,
      payload.role_ids
    );
  },

  fetchUsersForClinic(digitalClinicId) {
    return axios.get(`/api/digital-clinics/${digitalClinicId}/users`);
  },

  fetchClinicUser(payload) {
    return axios.get(
      `/api/digital-clinics/${payload.digitalClinicId}/users/${payload.userId}`
    );
  },

  fetchClinic(payload) {
    return axios.get(`/api/digital-clinics/${payload}`);
  },
};
