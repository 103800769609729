interface TabAuth {
  vet: boolean;
  admin: boolean;
}
interface Tab {
  title: string;
  tab: string;
  icon: string;
  tooltip: string;
  countValue: string;
  auth: TabAuth;
  platform: string;
}

const bookingTab: Tab = {
  title: 'Booking information',
  tab: 'info-tab',
  icon: 'information',
  tooltip: 'View booking and customer information',
  countValue: '',
  auth: {
    vet: true,
    admin: false,
  },
  platform: '',
};
const journalTab: Tab = {
  title: 'Journal',
  tab: 'journal-tab',
  icon: 'consultations',
  tooltip: 'Edit and view journal',
  countValue: '',
  auth: {
    vet: true,
    admin: false,
  },
  platform: '',
};

const prescriptionTab: Tab = {
  title: 'Prescription',
  tab: 'prescription-tab',
  icon: 'prescription',
  tooltip: 'Edit and view prescriptions',
  countValue: '',
  auth: {
    vet: true,
    admin: false,
  },
  platform: '',
};

const previousTab: Tab = {
  title: 'Previous records',
  tab: 'previous-records-tab',
  icon: 'encyclopedia',
  tooltip: `The AO's previous Firstvet records`,
  countValue: 'history',
  auth: {
    vet: true,
    admin: false,
  },
  platform: '',
};

const referralTab: Tab = {
  title: 'Contact a clinic',
  tab: 'referral-tab',
  icon: 'clinics',
  tooltip: 'Contact a clinic',
  countValue: 'referrals',
  auth: {
    vet: true,
    admin: false,
  },
  platform: '',
};

const appointmentUploadsTab: Tab = {
  title: 'Appointment uploads',
  tab: 'appointment-uploads-tab',
  icon: 'upload-40',
  tooltip: 'Appointment uploads',
  countValue: '',
  auth: {
    vet: true,
    admin: false,
  },
  platform: '',
};

const adminTab: Tab = {
  title: 'Admin',
  tab: 'admin-tab',
  icon: 'settings',
  tooltip: '',
  countValue: '',
  auth: {
    vet: true,
    admin: true,
  },
  platform: '',
};

const tabs: Tab[] = [
  bookingTab,
  journalTab,
  prescriptionTab,
  // NOTE: This tab is temporarily hidden for the realase of the BookingInfoTab (FV-1308).
  // animalTab,
  previousTab,
  referralTab,
  appointmentUploadsTab,
  adminTab,
];

export default tabs;
