import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { __DEV__, __STAGING__ } from '@/constants';
import { getDefaultApi } from '@/utils/http-utils';
const apiUrl = getDefaultApi();

const EchoInstance = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_KEY,
  encrypted: true,
  cluster: 'eu',
  authEndpoint: `${apiUrl}/broadcasting/auth`,
  auth: {
    headers: {
      Accept: 'application/json',
    },
  },
});

if (__DEV__ || __STAGING__) {
  Pusher.logToConsole = true;
}
export default EchoInstance;
