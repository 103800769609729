import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';
import { __PROD__ } from '@/constants';

export default {
  fetchPartners(countryId: number): AxiosPromise {
    const params = {
      countryId,
    };
    if (!__PROD__) {
      Object.assign(params, { test: 1 });
    }
    return axios.get(`/api/v2/partners`, { params });
  },
  lookupData(partnerId: number, payload: {}): AxiosPromise {
    return axios.post(`api/v2/partners/${partnerId}/lookup`, payload);
  },
  lookupFolksam(payload: {}): AxiosPromise {
    return axios.post(`/api/v2/remote-booking/lookup`, payload);
  },
  startFolksamNurseBooking(policyAnimalUUID: string, payload: { mobilePhone: string, ssn: string }): AxiosPromise {
    return axios.post(`/api/v2/remote-booking/start-booking/${policyAnimalUUID}`, payload);
  },
  fetchUserByAttributes(ssn: string, mobilePhone: string) {
    return axios.get(`api/v2/user/by-attribute`, {
      params: { ssn, mobilePhone },
    });
  },
  fetchAnimalsForUser(userId: string) {
    return axios.get(`api/v2/animals`, {
      params: { userId, withInsuranceCompany: 1 },
    });
  },
};
