<template>
  <transition
    name="fade"
    mode="out-in"
    @beforeLeave="beforeLeave"
    @enter="enter"
    @afterEnter="afterEnter"
  >
    <div
      v-if="show"
      class="fixed inset-0 z-50 bg-smoke flex "
    >
      <div
        v-click-outside="close"
        class="relative p-4 bg-white w-full max-w-md min-h-half m-auto flex-col flex border rounded"
      >
        <slot class="min-h-full" />
        <slot name="buttons">
          <base-button
            v-if="showCloseButton"
            color="cancel"
            @click="$emit('close')"
          >
            Close
          </base-button>
        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
import { fadeTransitions } from '@/mixins/index';

export default {
  mixins: [fadeTransitions],

  props: {
    show: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
