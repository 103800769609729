/* eslint-disable camelcase */
import { WaitingRoomApi } from '@/api';

export default {
  state: {
    appointmentQueue: [],
    totalInQueue: 0,
    echoTotalInQueue: null, // Fetched from websocket, listen to changes in totalInQueue for live updates
  },

  getters: {
    getFirstAppointmentIdInQueue: state =>
      state.appointmentQueue[0]?.appointment?.id,
    getAppointmentQueue: state => state.appointmentQueue,
    getAppointmentQueueMeta: state => ({
      totalInQueue: state.totalInQueue,
    }),
    getAppointmentQueueState: state => state,
    getEchoChangesInQueue: state => state.echoTotalInQueue,
  },

  mutations: {
    setAppointmentQueue: (state, payload) => {
      state.appointmentQueue = payload;
    },

    setAppointmentQueueMeta: (state, payload) => {
      state.totalInQueue = payload.totalInQueue;
    },

    setAppointmentQueueState: (state, payload) => {
      state.appointmentQueue = payload.appointmentQueue;
      state.totalInQueue = payload.totalInQueue;
    },

    setEchoTotalInQueue: (state, payload) => {
      state.echoTotalInQueue = payload;
    },
  },

  actions: {
    async fetchAppointmentQueue({ commit }, showHistory = false) {
      // for laravel api, history = 1 means show history, 0 means show current queue
      const history = showHistory ? 1 : 0;
      const { data, meta } = await WaitingRoomApi.fetchAppointmentQueue({
        history,
      });
      const { totalInQueue } = meta;

      commit('setAppointmentQueueState', {
        appointmentQueue: [...data],
        totalInQueue,
      });
    },
    addRandomUsersToTestQueue(_, nrOfUsersToAdd) {
      return WaitingRoomApi.addRandomUsersToTestQueue(nrOfUsersToAdd);
    },
  },
};
