<template>
  <td class="px-6 lg:px-8 py-3 whitespace-nowrap">
    <div class="">
      <slot />
    </div>
  </td>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
