<template>
  <select
    :class="[baseClasses, borderClasses, focusClasses, hoverClasses]"
    :value="value"
    :disabled="disabled"
    @change="$emit('change', $event.target.value)"
  >
    <option v-if="placeholder" value="" disabled selected class="text-gray-200">
      {{ placeholder }}
    </option>
    <option
      v-for="(opt, i) in options"
      :key="i"
      :value="opt[valueAttr]"
      :selected="opt.id === value"
    >
      {{ opt[labelAttr] }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    valueAttr: {
      type: [String],
      default: 'id',
    },
    labelAttr: {
      type: [String],
      default: 'name',
    },
    value: {
      type: [String, Number],
      default: undefined,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const baseClasses =
      'bg-white text-gray-800 rounded px-2 py-2 appearance-none cursor-pointer';
    const borderClasses = 'border border-gray-400';
    const focusClasses = 'focus:outline-none focus:border-primary-darker';
    const hoverClasses = 'hover:border-fv-gray-border-darker';
    return {
      baseClasses,
      focusClasses,
      hoverClasses,
      borderClasses,
    };
  },
};
</script>

<style lang="postcss"></style>
