<template>
  <div class="w-full flex flex-col space-y-1">
    <div>
      <base-label
        class=""
      >
        {{ title }}
        <span
          v-if="required"
          class="text-red-light"
        >*</span>
      </base-label>
      <span
        v-if="error"
        class="text-red-800 font-semibold text-xs ml-6"
      >
        Please select an option.
      </span>
    </div>

    <div class="flex items-center mb-2 space-x-4">
      <label
        :for="name + '-no'"
        class="mt-1 font-semibold text-xs"
      >
        No
        <ValidationProvider
          :rules="rules"
        >
          <input
            :id="name + '-no'"
            v-model="handle"
            type="radio"
            :value="0"
            :name="name"
          >
        </ValidationProvider>

      </label>
      <label
        class="mt-1 ml-3 font-semibold text-xs"
        :for="name + '-yes'"
      >
        Yes
        <input
          :id="name + '-yes'"
          v-model="handle"
          type="radio"
          :value="1"
          :name="name"
        >
      </label>
      <label
        v-if="extra"
        class="mr-2 mt-1 ml-3 font-semibold text-xs"
      >
        {{ extra.text }}
        <input
          :id="name"
          v-model="handle"
          type="radio"
          :value="extra.value"
          :name="name"
        >
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    name: {
      type: String,
      required: true,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    model: {
      type: [Boolean, Number],
      default: () => null,
    },
    extra: {
      type: Object,
      default: null,
    },
  },
  computed: {
    handle: {
      get() {
        return this.model;
      },
      set(val) {
        this.$emit('action', val);
      },
    },
  },
  $_veeValidate: {
    value() {
      return this.model;
    },
  },
};
</script>
