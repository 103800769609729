import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { __PROD__, SENTRY_URL } from '@/constants';

if (__PROD__) {
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
        attachProps: true,
        tracingOptions: {
          trackComponents: true,
        },
      }),
      new Integrations.BrowserTracing(),
    ],
    ignoreErrors: ['ResizeObserver loop limit exceeded'], // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const identifyUser = (userData: {}) => {
  Sentry.setUser(userData);
};

const setContext = (context: string, contextData: {}) => {
  Sentry.setContext(context, contextData);
};

export { identifyUser, setContext, Sentry };
