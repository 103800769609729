import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faUsers,
  faUserMd,
  faPercentage,
  faSpinner,
  faPhone,
  faPhoneSlash,
  faAlignLeft,
  faAngleRight,
  faAngleLeft,
  faSignOutAlt,
  faHome,
  faVideo,
  faSignInAlt,
  faTimes,
  faInfo,
  faInfoCircle,
  faFile,
  faFileContract,
  faFileSignature,
  faFileAlt,
  faFileWord,
  faFilePdf,
  faCapsules,
  faLaptopMedical,
  faPaw,
  faClinicMedical,
  faCog,
  faClock,
  faCaretRight,
  faEdit,
  faCommentMedical,
  faImages,
  faImage,
  faExclamationCircle,
  faExclamationTriangle,
  faSignal,
  faCalendarWeek,
  faCaretLeft,
  faCalendarAlt,
  faCheck,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faList,
  faStar,
  faCompressArrowsAlt,
  faExpandArrowsAlt,
  faPlay,
  faStop,
  faCompress,
  faExpand,
  faVolumeMute,
  faPause,
  faCircle,
  faVolumeUp,
  faVideoSlash,
  faMicrophone,
  faMicrophoneSlash,
  faQuestionCircle,
  faFilter,
  faUndo,
  faCaretDown,
  faCaretUp,
  faTachometerAlt,
  faPenAlt,
  faPen,
  faChartBar,
  faLifeRing,
  faUsersCog,
  faExternalLinkAlt,
  faBars,
  faTrash,
  faUserEdit,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faSync,
  faArrowAltCircleRight,
  faPlus,
  faShare,
  faExchangeAlt,
  faArrowRight,
  faArrowLeft,
  faMap,
  faEnvelopeOpen,
  faEnvelope,
  faArrowDown,
  faArrowUp,
  faChalkboardTeacher,
  faQuestion,
  faEnvelopeOpenText,
  faDoorOpen,
  faEye,
  faCommentDots,
  faCodeBranch,
  faCompass,
  faCloudUploadAlt,
  faCircleNotch,
  faMapPin,
  faThumbtack,
  faMinus,
  faCopy,
  faFolderOpen,
  faArrowCircleRight,
  faCalendarTimes,
  faComment,
  faWindowClose,
  faCommentSlash,
} from '@fortawesome/free-solid-svg-icons';

import {
  faClock as farClock,
  faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faEnvelopeOpen,
  faEnvelope,
  faEnvelopeOpenText,
  faMap,
  faUser,
  faUsers,
  faUserMd,
  faPercentage,
  faSpinner,
  faPhone,
  faPhoneSlash,
  faAlignLeft,
  faAngleRight,
  faAngleLeft,
  faDoorOpen,
  faSignOutAlt,
  faHome,
  faList,
  faVideo,
  faSignInAlt,
  faInfo,
  faInfoCircle,
  faFile,
  faFileContract,
  faFileSignature,
  faFileAlt,
  faFileWord,
  faFilePdf,
  faCapsules,
  faPaw,
  faLaptopMedical,
  faClinicMedical,
  faCommentMedical,
  faCog,
  faClock,
  faEdit,
  faImages,
  faImage,
  faExclamationCircle,
  faExclamationTriangle,
  faSignal,
  faFilter,

  faUndo,
  faSync,

  faCalendarWeek,
  faCalendarAlt,

  faCompressArrowsAlt,
  faExpandArrowsAlt,

  faCaretRight,
  faCaretLeft,
  faCaretDown,

  faCheck,
  faCheckCircle,
  faTimes,
  faTimesCircle,
  faSearch,
  faStar,
  faQuestionCircle,
  faQuestion,
  faCaretUp,

  faPlay,
  faStop,
  faCompress,
  faExpand,
  faVolumeMute,
  faVolumeUp,
  faPause,
  faCircle,
  faVideoSlash,
  faMicrophone,
  faMicrophoneSlash,
  faTachometerAlt,
  faPenAlt,
  faPen,
  faChartBar,

  faImage,
  faLifeRing,
  faUsersCog,
  faExternalLinkAlt,
  faBars,
  faTrash,
  faUserEdit,
  faEye,
  faCommentDots,

  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,

  farClock,
  faTrashAlt,
  faArrowAltCircleRight,
  faPlus,
  faMinus,

  faShare,
  faExchangeAlt,
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faArrowUp,
  faChalkboardTeacher,
  faCodeBranch,
  faCompass,
  faCloudUploadAlt,
  faCircleNotch,
  faMapPin,

  faThumbtack,

  faCopy,
  faFolderOpen,
  faArrowCircleRight,
  faCalendarTimes,
  faComment,
  faWindowClose,
  faCommentSlash
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
