/* eslint-disable import/extensions */
import Vue from 'vue';
import './directives';

import TitleRow from '@/components/typography/TitleRow.vue';
import {
  DescriptionData,
  DescriptionTitle,
  DescriptionList,
  DescriptionItem,
} from '@/UI/lists';

import { BaseButton, NavButton, TextButton } from '@/UI/buttons';

import { DefaultLayout, NarrowLayout } from '@/UI/layouts';

import {
  BaseSelect,
  CustomSelect,
  RadioSelect,
  ShadowSelect,
} from '@/UI/selects';
import { FvIcon } from '@/UI/icons/';

import Card from './cards/Card.vue';

import BaseInput from './inputs/base-input.vue';
import DisplayRow from './display-rows/display-row.vue';
import EditableRow from './display-rows/editable-row.vue';
import SecondaryInput from './inputs/secondary-input.vue';

import TextareaInput from './inputs/textarea-input.vue';
import BaseLabel from './inputs/BaseLabel.vue';
import BaseSwitch from './inputs/base-switch.vue';

import BaseSpinner from './spinners/BaseSpinner.vue';
import SpinnerOverlay from './spinners/SpinnerOverlay.vue';

import TableBody from './tables/TableBody.vue';
import TableData from './tables/TableData.vue';
import TableContainer from './tables/TableContainer.vue';

import BaseModal from './modals/base-modal.vue';
import ImageModal from './modals/image-modal.vue';

import NotificationPrimary from './notifications/notification-primary.vue';
import NotificationError from './notifications/notification-error.vue';
import NotificationAuth from './notifications/notification-auth.vue';
import NotificationBooking from './notifications/notification-booking.vue';

Vue.component('default-layout', DefaultLayout);
Vue.component('narrow-layout', NarrowLayout);

Vue.component('base-button', BaseButton);
Vue.component('text-button', TextButton);
Vue.component('nav-button', NavButton);
Vue.component('desc-list', DescriptionList);
Vue.component('desc-data', DescriptionData);
Vue.component('desc-title', DescriptionTitle);
Vue.component('desc-item', DescriptionItem);

Vue.component('base-label', BaseLabel);

Vue.component('base-input', BaseInput);
Vue.component('secondary-input', SecondaryInput);

Vue.component('display-row', DisplayRow);
Vue.component('editable-row', EditableRow);
Vue.component('title-row', TitleRow);

Vue.component('textarea-input', TextareaInput);
Vue.component('base-switch', BaseSwitch);

Vue.component('base-select', BaseSelect);
Vue.component('shadow-select', ShadowSelect);
Vue.component('radio-select', RadioSelect);
Vue.component('custom-select', CustomSelect);

Vue.component('table-body', TableBody);
Vue.component('table-container', TableContainer);
Vue.component('table-data', TableData);

Vue.component('spinner-overlay', SpinnerOverlay);
Vue.component('base-spinner', BaseSpinner);
Vue.component('card', Card);
Vue.component('title-row', TitleRow);

Vue.component('notification-primary', NotificationPrimary);
Vue.component('notification-error', NotificationError);
Vue.component('notification-auth', NotificationAuth);
Vue.component('notification-booking', NotificationBooking);

Vue.component('image-modal', ImageModal);
Vue.component('base-modal', BaseModal);

Vue.component('fv-icon', FvIcon);
