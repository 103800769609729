import { ClinicsApi } from '@/api';

const initialState = {
  clinics: [],
  countries: [],
  clinicTypes: [],
  animalTypeOptions: [],
  dayOptions: [],
  hourOptions: [],
  clinicSizeOptions: [],
  clinicEmergencyCareOptions: [],
  clinicStationaryCareOptions: [],
  clinicServiceOptions: [],
  clinicServiceCategories: [],
  clinicServiceAnimalGroups: [],
  filter: {},
};
const getters = {};
const mutations = {
  setClinics: (state, payload) => {
    state.clinics = Object.freeze(payload);
  },
  setCountries: (state, payload) => {
    state.countries = payload;
  },
  setClinicTypes: (state, payload) => {
    state.clinicTypes = payload;
  },
  setAnimalTypeOptions: (state, payload) => {
    state.animalTypeOptions = payload;
  },
  setDayOptions: (state, payload) => {
    state.dayOptions = payload;
  },
  setHourOptions: (state, payload) => {
    state.hourOptions = payload;
  },
  setClinicSizeOptions: (state, payload) => {
    state.clinicSizeOptions = payload;
  },
  setClinicEmergencyCareOptions: (state, payload) => {
    state.clinicEmergencyCareOptions = payload;
  },
  setClinicStationaryCareOptions: (state, payload) => {
    state.clinicStationaryCareOptions = payload;
  },
  setClinicServiceOptions: (state, payload) => {
    state.clinicServiceOptions = payload;
  },
  setClinicServiceCategories: (state, payload) => {
    state.clinicServiceCategories = payload;
  },
  setServiceAnimalGroups: (state, payload) => {
    state.clinicServiceAnimalGroups = payload;
  },
  setFilter: (state, payload) => {
    state.filter = payload;
  },
};
const actions = {
  fetchClinics: async (_, params = {}) => {
    try {
      const data = await ClinicsApi.filterClinics(params);
      //   commit('setClinics', data.clinics);
      console.log(data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  storeClinic: async (_, payload) => {
    try {
      const res = await ClinicsApi.storeClinic(payload);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  updateClinic: async (_, payload) => {
    try {
      const res = await ClinicsApi.updateClinic(payload);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchCountries: async ({ commit }) => {
    try {
      const { data } = await ClinicsApi.fetchCountries();
      const countries = Object.keys(data).map(index => {
        return {
          id: data[index].country_id,
          name: data[index].name,
          coordinates: data[index].coordinates,
          iso_3166_2: data[index].iso_3166_2,
        };
      });
      commit('setCountries', countries);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchClinicTypes: async ({ commit }, payload) => {
    try {
      const { data } = await ClinicsApi.fetchClinicTypes(payload);
      commit('setClinicTypes', data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  fetchAnimalTypes: async ({ commit }) => {
    try {
      const { data } = await ClinicsApi.getAllAnimalTypes();
      if (data) {
        commit('setAnimalTypeOptions', data.animal_types);
      }
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  addAnimalTypes: async (_, payload) => {
    try {
      const res = await ClinicsApi.addAnimalTypes(
        payload.animalTypeIds,
        payload.clinic
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  removeAnimalType: async (_, payload) => {
    try {
      const res = await ClinicsApi.removeAnimalType(
        payload.animalType,
        payload.clinic
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  syncServices: async (_, payload) => {
    try {
      const res = await ClinicsApi.syncServices(payload);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  attachService: async (_, payload) => {
    try {
      const res = await ClinicsApi.attachService(payload);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  detachService: async (_, payload) => {
    try {
      const res = await ClinicsApi.detachService(payload);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  editOpeningHours: async (_, { clinicId, openingHours, timezone }) => {
    try {
      const res = await ClinicsApi.editOpeningHours(
        clinicId,
        openingHours,
        timezone
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  fetchAvailableDays: async ({ commit }) => {
    try {
      const { data } = await ClinicsApi.getOpeningHours();
      commit('setDayOptions', data.dayOptions);
      commit('setHourOptions', data.hourOptions);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  fetchClinicSizeOptions: async ({ commit }) => {
    try {
      const { data } = await ClinicsApi.fetchClinicSizeOptions();
      commit('setClinicSizeOptions', data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  fetchEmergencyCareOptions: async ({ commit }) => {
    try {
      const { data } = await ClinicsApi.fetchEmergencyCareOptions();
      commit(
        'setClinicEmergencyCareOptions',
        data.filter(o => o.key !== 'simple_emergency_care')
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  fetchStationaryCareOptions: async ({ commit }) => {
    try {
      const { data } = await ClinicsApi.fetchStationaryCareOptions();
      commit('setClinicStationaryCareOptions', data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  fetchServiceOptions: async ({ commit }) => {
    try {
      const { data } = await ClinicsApi.fetchServiceOptions();
      commit('setClinicServiceOptions', data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  fetchServiceCategories: async ({ commit }) => {
    try {
      const { data } = await ClinicsApi.fetchServiceCategories();
      commit('setClinicServiceCategories', data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  fetchServiceAnimalGroups: async ({ commit }) => {
    try {
      const { data } = await ClinicsApi.fetchServiceAnimalGroups();
      commit('setServiceAnimalGroups', data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default { state: initialState, getters, mutations, actions };
