/* eslint-disable camelcase */
import { AppointmentApi } from '@/api';
import { PRE_SCREENING } from '@/config/service-keys';
import { format, zonedTimeToUtc } from 'date-fns-tz';

export default {
  state: {
    appointment: {
      weight: null,
      weight_unit: {},
    },
    appointmentUser: {},
    images: [],
    appointmentTypes: [],
    timezone: '',
    loading: false,
    roomURL: null,
    recentAppointments: [],
    weightUnits: {},
    appointmentCleared: false,
    caseTypes: [],
    bookingMetaData: null,
    caseType: null,
    triageJourney: {},
    videos: [],
  },

  getters: {
    getAppointment: state => state.appointment,
    getAppointmentUser: state => state.appointmentUser,
    getAppointmentId: state => (state.appointment ? state.appointment.id : 0),
    getRecentAppointments: state => state.recentAppointments,
    getBooking: state =>
      state.appointment && state.appointment.booking
        ? state.appointment.booking
        : {},
    getArchive: state =>
      state.appointment && state.appointment.video_archive
        ? state.appointment.video_archive
        : {},

    getImages: state => state.appointment?.images,
    getToken: state => state.token,
    getAppointmentTypes: state => state.appointmentTypes,
    getAppointmentType: state => state.appointment?.appointment_type,
    getTimezone: state => state.timezone,
    getLoading: state => state.loading,
    getDevice: state => state.appointment.device,
    getFunnel: state => state.appointment.funnel,
    getCustomerMobilePhoneNumber: state => {
      if (state.appointment && state.appointment.user) {
        return state.appointment.user.mobile_phone;
      }
      return null;
    },
    getIsPreScreeningMeeting(state, getters) {
      const isPreScreeningService =
        state.appointment?.booking?.service?.key === PRE_SCREENING ||
        !!state.appointment?.external_id;

      const isPreScreeningAppointmentType = !!(
        getters.getAppointmentType &&
        getters.getAppointmentType.id === 60 &&
        state.appointment.external_id
      );
      return isPreScreeningService || isPreScreeningAppointmentType;
    },
    getPresumedLanguage(state) {
      const languageList = state.appointment?.booking?.country?.languages;
      if (!languageList?.length) {
        return null;
      }
      return languageList[0];
    },
    getWeightUnits: state => state.weightUnits,
    getCustomerId: state => state.appointment.customer_id,
    getServiceChannel(state) {
      return state.appointment?.booking?.service?.key === 'veterinary_chat'
        ? 'chat'
        : 'video';
    },
    getTriageJourney: state => state.triageJourney,
  },

  mutations: {
    setAppointment: (state, payload) => {
      state.appointment = payload;
    },
    setAppointmentUser: (state, payload) => {
      const country = { ...payload.country };
      if (payload?.country?.timezone)
        country.timeZone = payload.country.timezone;
      state.appointmentUser.country = country;
    },
    setRecentAppointments: (state, payload) => {
      state.recentAppointments = payload;
    },
    setImages: (state, payload) => {
      state.appointment.images = payload;
      state.images = payload;
    },
    setImage: (state, payload) => {
      if (payload.fileName) {
        state.images.splice(payload.index, 1, {
          src: payload.image,
          name: payload.fileName,
        });
      } else {
        state.images.splice(payload.index, 1, payload.image);
      }
      return state.images;
    },
    setVideos: (state, payload) => {
      state.appointment.videos = payload;
      state.videos = payload;
    },
    setAppointmentTypes: (state, payload) => {
      state.appointmentTypes = payload;
    },
    setAppointmentType: (state, payload) => {
      state.appointment.appointment_type = payload;
    },
    setBookingDatetime: (state, payload) => {
      const formattedDate = format(
        zonedTimeToUtc(new Date(payload.booking_datetime), payload.timezone),
        'yyyy-MM-dd HH:mm'
      );
      state.appointment.booking.booking_datetime = formattedDate;
    },
    setTimezone: (state, payload) => {
      state.timezone = payload;
    },
    setLoading: (state, payload) => {
      state.loading = payload;
    },
    setD2cRoomURL: (state, url) => {
      state.roomURL = url;
    },
    setAppointmentUploads: (state, payload) => {
      state.appointment.journal_uploads = payload;
    },
    setWeightUnits: (state, payload) => {
      state.weightUnits = payload;
    },
    setWeightData: (state, payload) => {
      state.appointment.weight = payload.weight;
      state.appointment.weight_unit = payload.weight_unit;
    },
    setAppointmentCleared: (state, bool) => {
      state.appointmentCleared = bool;
      if (bool) {
        state.appointment = {
          weight: null,
          weight_unit: {},
        };
      }
    },
    setCaseTypes(state, caseTypes) {
      state.caseTypes = caseTypes;
    },
    setBookingMetaData(state, bookingMetaData) {
      state.bookingMetaData = bookingMetaData;
    },
    setCaseType(state, caseType) {
      state.caseType = caseType;
    },
    setTriageJourney(state, triageJourney) {
      state.triageJourney = triageJourney;
    },
  },

  actions: {
    async clearState({ commit, dispatch }) {
      // Clear previous values
      commit('setImages', []);

      commit('opentok/setToken', '', { root: true });
      commit('opentok/setSessionID', '', { root: true });

      commit('setAppointment', {});

      commit('setAppointmentUser', {});

      commit('setTimezone', '');

      dispatch('animal/clearAnimal', {}, { root: true });

      commit('insurance/setCompanies', [], { root: true });

      dispatch('journal/clearJournal', {}, { root: true });

      commit('referral/setAllJournalComments', [], { root: true });

      commit('journal/setProductRecommendations', [], { root: true });

      dispatch('referral/clearReferral', {}, { root: true });
    },
    async fetchAppointment(
      { commit, dispatch, rootState },
      { appointmentId, preview = false }
    ) {
      commit('setLoading', true);
      await dispatch('clearState');
      const { locale } = rootState;
      try {
        const { data } = await AppointmentApi.fetchAppointment(
          appointmentId,
          locale,
          preview
        );
        const {
          videos,
          appointment,
          token,
          timezone,
          followUp,
          triageJourney,
        } = data;
        const { user } = appointment;

        if (appointment) {
          commit('opentok/setToken', token, {
            root: true,
          });
          commit('opentok/setSessionID', appointment.session_id, {
            root: true,
          });

          commit('setAppointment', appointment);

          commit('setVideos', videos);

          commit('setAppointmentUser', appointment.user);

          commit('setTimezone', timezone);

          commit('setTriageJourney', triageJourney);

          if (appointment.booking) {
            commit('setBookingDatetime', {
              booking_datetime: appointment.booking.booking_datetime,
              timezone,
            });
          }

          const requests = [
            dispatch('insurance/fetchCompanies', appointment.customer_id, {
              root: true,
            }),
            dispatch('fetchBookingMetaData', appointment.id),
          ];

          if (appointment.animal_id) {
            requests.push(
              dispatch('animal/fetchAnimal', appointment.animal_id, {
                root: true,
              })
            );
          }

          if (appointment.case_type_id) {
            requests.push(dispatch('fetchCaseType', appointment.case_type_id));
          }

          await Promise.all(requests);

          commit('journal/setJournal', appointment.journal, {
            root: true,
          });
          commit('journal/setFollowUp', followUp, {
            root: true,
          });
          commit('animal/setAnimals', user.animals, {
            root: true,
          });

          const sharedAnimals = user.shared_animals.map(
            sharedRelationship => sharedRelationship.animal
          );
          commit('animal/setSharedAnimals', sharedAnimals, { root: true });

          commit('setLoading', false);
          return data;
        }

        throw new Error(`No appointment found with id ${appointmentId} found`);
      } catch (error) {
        commit('setLoading', false);
        return Promise.reject(error);
      }
    },
    async fetchAppointmentTypes({ commit }, payload) {
      const { data } = await AppointmentApi.fetchAppointmentTypes(payload);
      commit('setAppointmentTypes', data.appointment_types);
      return data.appointment_types;
    },
    async updateAppointment({ state }, payload) {
      const res = await AppointmentApi.update(state.appointment.id, payload);
      return res;
    },

    async generateAppointmentRoom({ state, commit }) {
      const { data } = await axios.post('/api/d2c-appointment', {
        mobile_phone: state.appointment.user.mobile_phone,
        country_code_id: state.appointment.user.country_code.id,
        code: state.appointment.user.country_code.code,
      });
      const URL = `${process.env.VUE_APP_MEET_URL}/room/${data.appointment.room}`;
      commit('setD2cRoomURL', URL);
      return URL;
    },

    async fetchRecentAppointments({ commit }) {
      try {
        const { data } = await AppointmentApi.fetchRecentAppointments();
        commit('setRecentAppointments', data.appointments);
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getWeightUnits({ commit, rootState }) {
      try {
        const countryId = rootState.user.country.id;
        const { data } = await AppointmentApi.getWeightUnits({
          country_id: countryId,
        });

        commit('setWeightUnits', data.weightUnits);
        return data;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchCaseTypes({ commit }, countryId) {
      const { data } = await AppointmentApi.fetchCaseTypes(countryId).catch(e =>
        Promise.reject(e)
      );
      commit('setCaseTypes', data.data);
      return data.data;
    },
    async fetchBookingMetaData({ commit }, appointmentId) {
      const { data } = await AppointmentApi.getBookingMetaData(appointmentId);
      commit('setBookingMetaData', data.bookingMetaData);
      return data;
    },
    async fetchCaseType({ commit }, caseTypeId) {
      const { data } = await AppointmentApi.getCaseType(caseTypeId);
      commit('setCaseType', data);
      return data;
    },
  },
};
