const supportStatuses = [
  {
    id: null,
    name: 'Any status',
  },
  {
    id: 0,
    name: 'Open',
  },
  {
    id: 1,
    name: 'Closed',
  },
];
const supportTicketTypes = [
  {
    id: null,
    name: 'Any issue type',
  },

  {
    id: 1,
    name: 'Technical issue',
  },
  {
    id: 2,
    name: 'Veterinary issue',
  },
  {
    id: 3,
    name: 'Customer support',
  },
  {
    id: 0,
    name: 'N/A',
  },
];
const supportPriorityLevels = [
  {
    id: null,
    name: '',
    shortName: 'Any priority',
  },
  {
    id: 1,
    name: 'High (need help right now)',
    shortName: 'High priority',
  },
  {
    id: 2,
    name: 'Medium (need help as soon as possible)',
    shortName: 'Medium priority',
  },
  {
    id: 3,
    name: 'Low (it can wait)',
    shortName: 'Low priority',
  },

  {
    id: 0,
    name: 'Priority not set',
    shortName: 'N/A',
  },
];

export { supportStatuses, supportPriorityLevels, supportTicketTypes };
