const SV_COUNTRY_ID = 752;
const UK_COUNTRY_ID = 826;
const NO_COUNTRY_ID = 578;
const FI_COUNTRY_ID = 246;
const DK_COUNTRY_ID = 208;
const DE_COUNTRY_ID = 276;
const US_COUNTRY_ID = 840;

export {
  SV_COUNTRY_ID,
  UK_COUNTRY_ID,
  NO_COUNTRY_ID,
  FI_COUNTRY_ID,
  DK_COUNTRY_ID,
  DE_COUNTRY_ID,
  US_COUNTRY_ID,
};
