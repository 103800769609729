<template>
  <label
    :class="[colorClassesString, sizeClassesString]"
    class="font-semibold select-none"
  > 
    <slot>Label</slot>
  </label>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['sm', 'md', 'lg'].includes(value);
      },
    },
    color: {
      type: String,
      default: 'dark',
      validator(value) {
        return ['dark', 'light'].includes(value);
      },
    },
  },
  setup(props) {
    const colorClassesHash = {
      dark: 'text-gray-900',
      light: 'text-gray-500',
    };

    const sizeClassesHash = {
      sm: 'text-sm',
      md: 'text-base',
      lg: 'text-lg',
    };

    return {
      colorClassesString: colorClassesHash[props.color],
      sizeClassesString: sizeClassesHash[props.size],
    };
  },
};
</script>
