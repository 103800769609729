import { InsuranceCompanyApi } from '@/api';

export default {
  state: {
    companies: [],
    insuranceList: [],
    partners: [],
  },

  getters: {
    getCompanies: state => state.companies,
    getInsuranceList: state => state.insuranceList,
    getPartners: state => state.partners,
    getPartnerList: state =>
      state.partners.map(partner => ({
        id: partner.id,
        name: partner.insurance.name,
      })),
  },

  mutations: {
    setCompanies: (state, payload) => {
      state.companies = payload;
    },
    setInsuranceList: (state, payload) => {
      state.insuranceList = payload;
    },
    setPartners: (state, payload) => {
      state.partners = payload;
    },
  },

  actions: {
    fetchPartners({ commit }, payload) {
      return InsuranceCompanyApi.insuranceCompanies(payload)
        .then(res => {
          commit('setPartners', res.data.partners);
          return res;
        })
        .catch(e => Promise.reject(e));
    },
    fetchInsuranceCompanies({ commit }) {
      InsuranceCompanyApi.getAll()
        .then(res => {
          commit('setCompanies', res.data.companies);
          return res;
        })
        .catch(e => Promise.reject(e));
    },
    fetchCompanies({ commit }, payload) {
      InsuranceCompanyApi.getAll({ customerId: payload })
        .then(res => {
          commit('setCompanies', res.data.companies);
          return res;
        })
        .catch(e => Promise.reject(e));
    },
    fetchCompaniesByCountryId({ commit }, payload) {
      InsuranceCompanyApi.getAllByCountryId(payload.countryId, payload.params)
        .then(res => {
          commit('setInsuranceList', res.data.data || res.data.insurance);
          return res;
        })
        .catch(e => Promise.reject(e));
    },
  },
};
