import { CountryApi } from '@/api';

export default {
  state: {
    countryCodes: [],
    timezones: [],
    languages: [],
    countries: [],
    usStates: [],
  },

  getters: {
    getCountries: state => state.countries,
    getLanguages: state => state.languages,
    country(state) {
      return state.countries.find(country => country.id === state.countryId);
    },
    countryTimezone(getters) {
      const selectedCountry = getters.country;
      return selectedCountry
        ? selectedCountry.timeZone || selectedCountry.timezone
        : 'Europe/London';
    },
  },

  mutations: {
    setCountryCodes: (state, payload) => {
      state.countryCodes = payload;
    },
    setTimezones: (state, payload) => {
      state.timezones = payload;
    },
    setLanguages: (state, payload) => {
      state.languages = payload;
    },
    setCountries(state, payload) {
      state.countries = payload;
    },
    setUsStates(state, payload) {
      state.usStates = payload;
    },
  },

  actions: {
    async fetchCountryCodes({ commit }) {
      const { data } = await CountryApi.fetchCountryCodes();
      commit('setCountryCodes', data.country_codes);
      return data;
    },
    async fetchTimezones({ commit }) {
      const { data } = await CountryApi.fetchTimeZones();
      commit('setTimezones', data.timezones);
      return data;
    },
    async fetchLanguages({ commit }) {
      const { data } = await CountryApi.fetchLanguages();
      commit('setLanguages', data.languages);
      return data;
    },
    async fetchCountries({ commit }) {
      const { data } = await CountryApi.fetchCountries();
      commit('setCountries', Object.values(data));
      return data;
    },
    async fetchUsStates({ commit }) {
      const { data } = await CountryApi.fetchUsStates();
      commit('setUsStates', data.us_states);
      return data;
    },
  },
};
