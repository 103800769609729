import { SV_COUNTRY_ID } from '@/config/countries';

export default [
  {
    name: 'ecommerce',
    path: '/ecommerce/:country',
    beforeEnter(to) {
      if (to.params.country == SV_COUNTRY_ID) {
        location.href = 'https://firstvet.com/sv/butik';
      }
    },
  },
  {
    name: 'handbook',
    path: '/handbook/:country',
    beforeEnter(to) {
      if (to.params.country == SV_COUNTRY_ID) {
        location.href =
          'https://docs.google.com/document/d/19UzTS2sLME_qv3nNCldqjCNi1z-zoxSqHhDXiveF_0Y/';
      }
    },
  },
];
