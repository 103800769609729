import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  getStats(): AxiosPromise {
    return axios.get('/api/vet/stats');
  },
  getStatsHourly(toDate: String, fromDate: String): AxiosPromise {
    return axios.get('/api/vet/stats/hours', { params: { toDate, fromDate } });
  },
  getTotalCount(): AxiosPromise {
    return axios.get('/api/vet/total-count');
  },
};
