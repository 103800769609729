import { QuestionsApi } from '@/api';

export default {
  state: {
    questions: [],
    categories: [],
    question: null,
  },

  getters: {
    getQuestions: state => state.questions,
    getCategories: state => state.categories,
    next_page_url: state =>
      state.questions.next_page_url ? state.questions.next_page_url : '',
    prev_page_url: state =>
      state.questions.prev_page_url ? state.questions.prev_page_url : '',
    last_page: state =>
      state.questions.last_page ? state.questions.last_page : '',
    current_page: state =>
      state.questions.current_page ? state.questions.current_page : '',
  },

  mutations: {
    setQuestions: (state, payload) => {
      state.questions = payload;
    },
    setCategories: (state, payload) => {
      state.categories = payload;
    },
    setQuestion: (state, payload) => {
      state.question = payload;
    },
  },

  actions: {
    fetchQuestion({ commit }, payload) {
      return QuestionsApi.fetchQuestion(payload)
        .then(res => {
          commit('setQuestion', res.data.question);
          return res;
        })
        .catch(e => {
          return Promise.reject(e);
        });
    },
    fetchQuestions({ commit }, payload) {
      return QuestionsApi.fetchQuestions(payload)
        .then(res => {
          commit('setQuestions', res.data.questions);
          commit('setCategories', res.data.categories);
          return res;
        })
        .catch(e => {
          return Promise.reject(e);
        });
    },
    queryQuestions({ commit }, payload) {
      return QuestionsApi.queryQuestions(payload)
        .then(res => {
          commit('setQuestions', res.data.questions);
          return res;
        })
        .catch(e => {
          return Promise.reject(e);
        });
    },
    fetchMoreQuestions({ commit }, payload) {
      return QuestionsApi.fetchMore(payload)
        .then(res => {
          commit('setQuestions', res.data.questions);
          return res;
        })
        .catch(e => {
          return Promise.reject(e);
        });
    },
    updateQuestion({ dispatch }, payload) {
      return QuestionsApi.update(payload)
        .then(res => {
          dispatch('fetchQuestion', res.data.question.id);
          return res;
        })
        .catch(e => {
          return Promise.reject(e);
        });
    },
  },
};
