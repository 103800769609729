import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  localize,
} from 'vee-validate/dist/vee-validate.full';
import en from 'vee-validate/dist/locale/en.json';

localize({
  en: {
    fields: {
      email: {
        required: () => 'Email is required.',
        email: () => 'A valid email is required',
      },
      passwordConfirmation: {
        required: () => 'The confirm password field is required.',
        is: () => 'The passwords must be an exact match',
      },
      reason: {
        required: () => 'You need to enter the reason for the referral.',
        min: () => 'A minimum of 8 characters is required.',
      },
      referredFor: {
        required: () => 'A description of the animals condition is needed',
        min: () => 'A minimum of 8 characters is required.',
      },
      emergency: {
        required: () => 'Enter how urgent the case is.',
        min: () => 'A minimum of 4 characters is required.',
      },
      anamnesis: {
        required: () => 'Please enter a case description.',
        min: () => 'A minimum of 8 characters is required.',
      },
      currentStatus: {
        required: () => "Please enter the animal's current status.",
        min: () => 'A minimum of 8 characters is required.',
      },
      treatment: {
        required: () => 'Please enter your treatment recommendation.',
        min: () => 'A minimum of 8 characters is required.',
      },
      appointmentType: {
        required: () => 'Please select a case type.',
        min: () => 'Please select a case type.',
      },
      referralType: {
        required: () => 'Please select a referral instruction.',
      },
      diagnosisCodes: {
        required: () => 'Please select at least one diagnosis code.',
      },
      caseDescription: {
        required: () => 'Please enter a case description.',
      },
    },
  },
});

localize({
  en,
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
