// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

const formDataConfig = {
  headers: {
    'content-type': 'multipart/form-data',
  },
};

export default {
  upload(appointmentID: any, file: any): AxiosPromise {
    const formData = new FormData();
    formData.append('attachment', file);
    formData.append('appointmentId', appointmentID);
    return axios.post('/appointment-uploads', formData, formDataConfig);
  },
  getAppointmentUpload(id: number): AxiosPromise {
    return axios.get(`/appointment-uploads/${id}`);
  },
  deleteAppointmentUpload(id: number): AxiosPromise {
    return axios.delete(`/appointment-uploads/${id}`);
  },
};
