import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';
import UserWorkHours from '@/config/user-work-hours';

export default {
  fetchUser(): AxiosPromise {
    return axios.get('/api/auth/vet');
  },
  fetchUserDetails(id: number): AxiosPromise {
    if (id) return axios.get(`/api/vet/details/${id}`);
    return axios.get(`/api/vet/details`);
  },
  updateUserDetails(user: any): AxiosPromise {
    return axios.put(`/api/vet/details/${user.id}`, { ...user });
  },
  updateAnimalTypes(animalTypes, userId) {
    return axios.put(`api/vet/animal-types/${userId}`, { animalTypes });
  },
  updateLanguages(languages, userId) {
    return axios.put(`api/vet/languages/${userId}`, { languages });
  },
  getLanguages(userId) {
    return axios.get(`api/vet/languages/${userId}`);
  },
  updatePassword({ password, newPassword }) {
    return axios.put(`api/auth/vet/password`, { password, newPassword });
  },
  removeAvatar(userId: number) {
    return axios.delete(`api/vet/avatar/${userId}`);
  },
  updateAvatar(avatar, userId: number) {
    return axios.post(`api/vet/avatar/${userId}`, avatar, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  },
  fetchAvatar(userId: number) {
    return axios.get(`api/vet/avatar/${userId}`);
  },
  removeCallNotificationAvatar(userId: number) {
    return axios.delete(`api/vet/avatar/call-notification/${userId}`);
  },
  updateCallNotificationAvatar(avatar, userId: number) {
    return axios.post(`api/vet/avatar/call-notification/${userId}`, avatar, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  },
  fetchCallNotificationAvatar(userId: number) {
    return axios.get(`api/vet/avatar/call-notification/${userId}`);
  },

  fetchNotificationTypes() {
    return axios.get('api/notifications');
  },
  updateNotifications(notifications, userId) {
    return axios.put(`api/vet/notifications/${userId}`, { notifications });
  },
  updateStateLicences(states, userId) {
    return axios.put(`api/vet/us-state-licenses/${userId}`, { states });
  },
  updateWorkHours(
    { start, end }: UserWorkHours,
    userId
  ): AxiosPromise<UserWorkHours> {
    return axios.put(`api/vet/work-hours/${userId}`, { start, end });
  },
  createTestSchedule(
    userId: number,
    payload?: { start?: string; end?: string; serviceId?: number }
  ) {
    return axios.post(`api/vet/${userId}/test-schedule`, payload);
  },
  createTestBookedAppointment(payload: {
    userId: number;
    animalId: number;
    start: string;
  }) {
    return axios.post(`api/vet/test-booked-appointment`, payload);
  },
};
