<template>
  <tbody
    class="relative"
  >
    <slot />
  </tbody>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
