<template>
  <div
    class="bg-white bg-opacity-75 rounded shadow relative"
    :class="{ 'p-4': usePadding }"
  >
    <spinner-overlay
      size="xl"
      color="light"
      :loading="loading"
    />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    usePadding: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
