/* eslint-disable camelcase */
import { JournalApi } from '@/api';

export default {
  state: {
    prescriptions: [],
    numberOfUnsigned: 0,
  },

  getters: {
    getPrescriptions: state => state.prescriptions,
    getNumberOfUnsigned: state => state.numberOfUnsigned,
  },

  mutations: {
    setNumberOfUnsigned: (state, payload) => {
      state.numberOfUnsigned = payload;
    },
    setPrescriptions: (state, payload) => {
      state.prescriptions = payload;
    },
  },

  actions: {
    async fetchPrescriptions({ commit }) {
      const { data, meta } = await JournalApi.fetchUnsignedPrescriptions();

      const { numberOfUnsigned } = meta;
      commit('setPrescriptions', data);
      commit('setNumberOfUnsigned', numberOfUnsigned);
    },
  },
};
