/* eslint-disable prettier/prettier */
import { mapState, mapGetters, mapActions } from 'vuex';

export const authComputed = {
  ...mapState('auth', {
    authenticated: state => state.authenticated,
    countryCodes: state => state.countryCodes,
    use2fa: state => state.use2fa,

  }),
  ...mapGetters('auth', [
    'getRoles',
    'isAdmin',
    'canManageArticles',
    'canManageQuestions'
  ])
};

export const authMethods = {
  ...mapActions('auth', [
    'login',
    'logout',
    'loginTwoFactor',
    'submitCodeTwoFactor',
    'setAuthData',
    'handleLoginRoutePush'
  ])
};
