<template>
  <div
    id="app"
    class="h-screen max-h-screen w-full overflow-auto bg-beigeLighter font-sans text-gray-900"
  >
    <transition name="fade">
      <notification-primary />
    </transition>
    <transition name="fade">
      <notification-error />
    </transition>
    <transition name="fade">
      <notification-auth />
    </transition>
    <transition name="fade">
      <notification-booking />
    </transition>
    <router-view v-if="user || isGuestRoute" />
    <div
      v-else
      class="relative flex h-full w-full flex-col items-center justify-center bg-beigeLighter"
    >
      <div
        class="mx-auto my-auto flex flex-col space-y-4 text-center text-gray-700"
      >
        <img
          :src="require('@/assets/svg/loading_relax.svg')"
          class="mx-auto my-auto h-64 w-56"
        />

        <p class="mx-auto mt-4 inline-flex space-x-2 font-semibold">
          <span class="font-display text-lg">Initializing..</span>
          <span>
            <base-spinner :loading="loading" size="lg" />
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';
import { __STAGING__ } from '@/constants';

export default defineComponent({
  name: 'Vetpanel',

  data() {
    return {
      isStaging: __STAGING__,
      loading: false,
    };
  },

  computed: {
    ...mapState(['showTimeline', 'showSupportTicket']),
    ...mapGetters('user', {
      user: 'getUser',
    }),
    isGuestRoute() {
      return this.$route.meta && this.$route.meta.guest;
    },
  },
  async mounted() {
    await this.fetchVersion();

    setInterval(() => {
      this.compareAppVersions();
    }, 60000 * 10);
  },

  methods: {
    ...mapMutations(['toggleSupportTicketModal']),
    ...mapActions(['compareAppVersions', 'fetchVersion']),
  },
});
</script>
