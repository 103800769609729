// eslint-disable-next-line import/no-cycle
import axios from '@/plugins/axios';

export default {
  async validate(params = {}) {
    const { data } = await axios.post('api/clinic-listing/validate', params);
    return data;
  },

  async send(params = {}) {
    const { data } = await axios.post('api/clinic-listing', params);
    return data;
  },
};
