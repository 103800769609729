<template>
  <div class="flex justify-center items-center h-full">
    <div class="w-full lg:w-2/5">
      <card class="mx-2 justify-center mb-0">
        <div class="flex flex-col py-4 space-x-6">
          <div class="w-full pb-2 text-center">
            <img
              :src="require('@/assets/svg/firstvet_logo.svg')"
              class="w-auto h-12 mx-auto"
            >
          </div>
        </div>

        <ValidationObserver
          ref="observer"
          v-slot="{handleSubmit}"
        >
          <form
            v-if="!passwordIsSet"
            class="flex flex-wrap justify-center mx-8"
            @submit.prevent="handleSubmit(submit)"
          >
            <p
              v-if="!passwordIsSet"
              class="font-semibold text-left text-lg mt-4 pb-2 border-b w-full mb-6"
            >
              Set your new password
            </p>

            <ValidationProvider
              v-slot="{ errors }"
              rules="required|min:8"
              name="password"
              vid="password"
              tag="div"
              class="w-full mb-4"
            >
              <base-label for="password">
                Password
              </base-label>
              <base-input
                id="password"
                v-model="password"
                type="password"
                placeholder="***********"
                name="password"
              />
              <p
                v-if="errors.length"
                class="text-red-800 mt-2"
              >
                {{ errors[0] }}
              </p>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: true, is: password }"
              name="passwordConfirmation"
              vid="passwordConfirmation"
              class="w-full mb-4"
            >
              <div
                v-if="password.length > 7"
              >
                <base-label for="passwordConfirmation">
                  Confirm password
                </base-label>
                <base-input
                  id="passwordConfirmation"
                  v-model="passwordConfirmation"
                  type="password"
                  placeholder="***********"
                  name="passwordConfirmation"
                />
                <p
                  v-if="errors.length"
                  class="text-red-800 mt-2"
                >
                  {{ errors[0] }}
                </p>
              </div>
            </ValidationProvider>
            <p
              v-if="error"
              class="text-red-700"
            >
              {{ error }}
            </p>
            <base-button
              :loading="loading"
              color="primary"
              class="w-full mb-6 text-center"
              :disabled="loading"
            >
              Submit
            </base-button>
          </form>
        </ValidationObserver>

        <div
          v-if="passwordIsSet"
          class="flex flex-col justify-center items-center"
        >
          <fv-icon
            icon="check-mark"
            class="mr-3 text-fv-green mb-2"
            size="3x"
          />
          <p class="font-semibold text-xl mt-2 mb-6 text-center">
            Your new password has been set!
          </p>
          <base-button
            :loading="loading"
            color="primary"
            class="w-full mb-6 mt-6 text-center"
            @click="showTwoFactorModal = true"
          >
            Sign in
          </base-button>
          <div />
        </div>
      </card>
    </div>
    <two-factor-modal
      :show="showTwoFactorModal"
      :phone-number="phoneNumber"
      :country-code="countryCode"
      :temp-token="tempToken"
      @close="showTwoFactorModal = false"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TwoFactorModal from '@/components/auth/TwoFactorModal.vue';
import { AuthApi } from '@/api';
import { authMethods } from '@/store/auth-helpers';

export default {
  components: {
    TwoFactorModal,
  },
  data() {
    return {
      loading: false,
      password: '',
      passwordConfirmation: '',
      email: '',
      error: '',
      logo: '',
      logoError: '',
      name: '',

      showTwoFactorModal: false,

      phoneNumber: '',

      tempToken: '',
      countryCode: 3,
      passwordIsSet: false,
    };
  },

  mounted() {
    this.getValuesfromQuery();
    this.fetchCountryCodes();
  },

  methods: {
    ...authMethods,
    ...mapActions('country', ['fetchCountryCodes']),

    getValuesfromQuery() {
      this.email = this.$router.currentRoute.query.email;
      this.token = this.$router.currentRoute.query.token;
    },
    async submit() {
      this.loading = true;
      this.error = '';

      try {
        const res = await AuthApi.resetPassword({
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          token: this.token,
        });

        this.$notify({
          group: 'auth',
          title: 'Success!',
          text: `Password was successfully set`,
        });
        this.passwordIsSet = true;
        this.phoneNumber = res.data.user.mobile_phone
          ? res.data.user.mobile_phone
          : '';
        this.countryCode = res.data.user.country_code.id;
        this.loading = false;

        this.tempToken = res.data.token;
      } catch (e) {
        this.loading = false;
        this.error = e.message;
      }
    },
  },
};
</script>
