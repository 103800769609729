<template>
  <component
    :is="type"
    :to="to"
    :exact="exact"
    :disabled="disabled"
    :class="[
      baseClasses,
      colorClasses,
      disabled ? 'pointer-events-none opacity-75' : '',
    ]"
    @click="$emit('click')"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    color: {
      validator(value) {
        return ['white', 'dark'].includes(value);
      },
      type: String,
      default: 'white',
    },
    size: {
      validator(value) {
        return ['xs', 'sm', 'md', 'lg'].includes(value);
      },
      type: String,
      default: 'md',
    },
    to: {
      type: [Boolean, String, Object],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    exact: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const baseClasses =
      'flex justify-center text-xl px-4 cursor-pointer transition ease-in-out duration-250';
    const colorClasses = {
      white:
        'bg-transparent text-white focus:outline-none hover:bg-primary-darker focus:bg-primary-darker active:bg-primary-darker',
      dark: 'text-gray-800 focus:outline-none active:text-primary-darker hover:text-primary-darker',
    };

    return { baseClasses, colorClasses: colorClasses[props.color] };
  },
  computed: {
    type() {
      return this.to ? 'router-link' : 'button';
    },
  },
};
</script>
