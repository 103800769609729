// eslint-disable-next-line import/no-cycle
import store from '@/store';
import { getPlatform } from '@/utils/http-utils';

const missingPlatformToken = platformToCheck => {
  const currentPlatform = getPlatform();
  const platformToken = localStorage.getItem(`${currentPlatform}Token`);
  if (currentPlatform === platformToCheck && !platformToken) {
    return true;
  }
  return false;
};

const guards = {
  async requireAuth(to, from, next) {
    const platform = getPlatform();
    if (to.matched.some(record => record.meta.auth)) {
      // No token is set, redirect to root page (login)
      if (localStorage.getItem('intlToken') == null) {
        if (platform === 'intl') {
          next({
            path: '/',
            params: { nextUrl: to.fullPath },
          });
        }
      }
      if (missingPlatformToken(platform, 'sv')) {
        next({ path: `/${platform}` });
      } else {
        // Token is set, presume valid auth
        store.commit('auth/setAuth', true);

        // If current route is an appointment, and the call is active
        // ask if they are sure about leaving the page.
        if (
          from.matched.some(record => record.name === 'appointment') &&
          store.getters['opentok/getActiveOrActivating']
        ) {
          // eslint-disable-next-line no-alert
          const sureLeave = window.confirm(
            'You still have an active video connection. Sure you want to disconnect and leave?'
          );
          if (sureLeave) {
            next();
          }
        } else {
          // If token is set, but no user is found in state, fetch the user.
          if (!store.getters['user/getUser']) {
            await store.dispatch('user/fetchUser');
          }

          const isAdmin = store.getters['auth/isAdmin'];
          const isDigitalClinicAdmin =
            store.getters['auth/isDigitalClinicAdmin'];
          const hasSelectedDigitalClinic =
            store.getters['admin/digital-clinic/getDigitalClinicId'];
          const isFolksamTriageNurse =
            store.getters['auth/isFolksamTriageNurse'];

          if (isFolksamTriageNurse) {
            if (to.matched.some(record => record.meta.isFolksamTriageNurse)) {
              next();
            } else {
              next({ path: `${platform}/call-triage` });
            }
          } else if (
            to.matched.some(record => record.meta.digitalClinicOnly) &&
            !hasSelectedDigitalClinic &&
            isAdmin
          ) {
            next({ name: 'admin' });
          } else if (
            to.matched.some(record => record.meta.digitalClinicAdmin)
          ) {
            if (isDigitalClinicAdmin || isAdmin) {
              next();
            } else {
              next({ name: 'dashboard' });
            }
          } else if (to.matched.some(record => record.meta.admin)) {
            if (isAdmin) {
              next();
            } else {
              next({ name: 'dashboard' });
            }
          } else {
            next();
          }
        }
      }
    } else if (to.matched.some(record => record.meta.guest)) {
      if (
        missingPlatformToken('sv') ||
        missingPlatformToken('intl') ||
        missingPlatformToken('us')
      ) {
        next();
      } else {
        next({ name: 'dashboard' });
      }
    } else {
      next();
    }
  },
};

export default guards;
