export const makeCallTriageStoreKey = session => `call-triage-store-${session}`;

const callTriageStorePersister = store => {
  store.subscribe((mutation, state) => {
    if (!mutation.type.includes('callTriage') || !state.callTriage.session) {
      return;
    }
    const callTriageState = state.callTriage;
    if (!callTriageState?.session) {
      return;
    }
    sessionStorage.setItem(
      makeCallTriageStoreKey(callTriageState.session),
      JSON.stringify(callTriageState)
    );
  });
};

export default callTriageStorePersister;
