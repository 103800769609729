// Clinic API module
export default {
  async getClinics(params = null) {
    try {
      const { data } = await axios.get('/clinic-map/filter', { params });
      return data.clinics;
    } catch (e) {
      return Error(e);
    }
  },
  async getClinic(id, params = {}) {
    try {
      const res = await axios.get(`/api/clinic/${id}`, { params });
      return res;
    } catch (e) {
      return Error(e);
    }
  },
  async storeClinic(data) {
    try {
      const res = await axios.post('/api/clinic/store', { ...data });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateClinic(clinic) {
    try {
      const { data } = await axios.patch(
        `/api/clinic/${clinic.id}/update`,
        clinic
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async updateClinicArea(clinic) {
    try {
      const { data } = await axios.patch(
        `api/clinic-area/${clinic.id}/update`,
        clinic
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async fetchReferrals(clinicId, payload) {
    try {
      const { data } = await axios.get(`/api/clinic/${clinicId}/referrals`, {
        params: payload,
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async fetchReferralsPaginate(url) {
    try {
      const [, endpoint] = url.split('api');
      const { data } = await axios.get(`/api${endpoint}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async storeReferralFollowup(params) {
    try {
      const { data } = await axios.put(`/api/clinic/referral/followup`, params);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async syncServices({ clinicId, categoryId, animalGroupId, services }) {
    try {
      const res = await axios.put(`/api/clinic/${clinicId}/services`, {
        categoryId,
        animalGroupId,
        services,
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async attachService({ clinicId, serviceId, animalGroupId }) {
    try {
      const { data } = await axios.post('/api/clinic/attachservice', {
        clinicId,
        serviceId,
        animalGroupId,
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async detachService({ clinicId, serviceId, animalGroupId }) {
    try {
      console.log('Detaching service');
      const { data } = await axios.post('/api/clinic/detachservice', {
        clinicId,
        serviceId,
        animalGroupId,
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async filterClinics(params = null) {
    try {
      const { data } = await axios.get('/clinic-map/filter', { params });
      // if (!params.paginate && data?.clinics) {
      //   data.clinics = data.clinics.filter(c => c.clinic_type); // Remove 2288 clinic
      // }
      return data.clinics;
    } catch (e) {
      return Error(e);
    }
  },
  async fetchCountries() {
    try {
      const res = axios({
        url: '/api/country-coordinates',
        headers: {
          'Accept-Language': 'en',
        },
      });
      return res;
    } catch (error) {
      return Error(error);
    }
  },
  async fetchClinicTypes(payload) {
    try {
      const res = await axios.get('/clinic-types', { params: payload });
      return res;
    } catch (error) {
      return Error(error);
    }
  },
  async getAllAnimalTypes() {
    try {
      return await axios.get('/clinics-available-animal-types');
    } catch (error) {
      console.log('error', error);
      return Error(error);
    }
  },
  async addAnimalTypes(animalTypeIds, clinicId) {
    try {
      const res = await axios.post(`/api/clinic/animaltypes/${clinicId}`, {
        animalTypeIds,
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async removeAnimalType(animalTypeId, clinicId) {
    try {
      const res = await axios.delete(
        `/api/clinic/animaltype/${animalTypeId}/${clinicId}`
      );
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async editOpeningHours(clinicId, openingHours, timezone) {
    try {
      const res = await axios.post(`/api/clinic/opening-hours/${clinicId}`, {
        opening_hours: openingHours,
        timezone,
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getOpeningHours() {
    try {
      const res = await axios.get('/clinic-opening-day-options');
      console.log('Day options', res);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async fetchEmergencyCareOptions() {
    try {
      const res = await axios.get('/clinic-emergency-care-options');
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchStationaryCareOptions() {
    try {
      const res = await axios.get('/clinic-stationary-care');
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchClinicSizeOptions() {
    try {
      const res = await axios.get('/clinic-sizes');
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchServiceOptions() {
    try {
      const res = await axios.get('/clinic-services');
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async fetchServiceCategories() {
    try {
      const res = await axios.get('/clinic-service-categories');
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async fetchServiceAnimalGroups() {
    try {
      const res = await axios.get('/clinic-service-animal-groups');
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async setInsuranceRating(clinicId, insuranceCompanyId, rating) {
    const res = await axios.put(
      `/api/clinic/${clinicId}/insurance-rating/${insuranceCompanyId}`,
      { rating }
    );
    return res;
  },
};
