import { SupportApi } from '@/api';
import {
  supportPriorityLevels,
  supportStatuses,
  supportTicketTypes,
} from '@/config/support-tickets';

const hydrateTicket = (ticket, ticketList) => {
  const position = ticketList.findIndex(item => item.id === ticket.id);
  const hydrated = [...ticketList];
  hydrated[position] = ticket;
  return hydrated;
};
const addNewAnswer = (answer, ticketList) => {
  const position = ticketList.findIndex(item => item.id === answer.support_id);
  if (position !== -1) {
    const hydrated = [...ticketList];
    if (!hydrated[position].answers) hydrated[position].answers = [];
    hydrated[position].answers.unshift(answer);
    return hydrated;
  }
  return ticketList;
};

export default {
  state: {
    ticketList: {
      data: [],
    },
    filters: {
      status: null,
      type: null,
      priority: null,
      vetId: null,
      countryId: null,
      categories: [],
    },
    selectedTicket: {
      answers: [],
    },
    previousTicketId: null,
    statuses: supportStatuses,
    types: supportTicketTypes,
    priorityList: supportPriorityLevels,
    showTicketModal: false,
    hasNewSupportMessages: false,
  },
  getters: {
    getTicketList: state => state.ticketList,
    getSelectedTicket: state => state.selectedTicket,
    userIsAdmin(_, __, ___, rootGetters) {
      return rootGetters['auth/isAdmin'];
    },
    userId(_, __, rootState) {
      return rootState.user.user.id;
    },
    userCountry(_, __, rootState) {
      return rootState.user.user.country_id;
    },
    getPreSelectedCategories: state => {
      return state.filters.categories;
    },
    getShowTicketModal: state => state.showTicketModal,
    getHasNewSupportMessages: state => state.hasNewSupportMessages,
  },
  mutations: {
    setTicketList: (state, payload) => {
      state.ticketList = payload;
    },
    addTicket: (state, payload) => {
      state.ticketList.data.unshift(payload);
    },
    setStatus: (state, status) => {
      state.filters = {
        ...state.filters,
        status,
      };
    },
    setPriority: (state, priority) => {
      state.filters = {
        ...state.filters,
        priority,
      };
    },
    setType: (state, type) => {
      state.filters = {
        ...state.filters,
        type,
      };
    },
    setVet: (state, vetId) => {
      state.filters = {
        ...state.filters,
        vetId,
      };
    },
    setCountryId: (state, countryId) => {
      state.filters = {
        ...state.filters,
        countryId,
      };
    },
    setCategories: (state, categories) => {
      state.filters.categories = categories;
    },
    resetFilters: state => {
      state.filters = {
        status: null,
        type: null,
        priority: null,
        vetId: null,
        categories: [],
      };
    },
    setSelectedTicket: (state, payload) => {
      state.selectedTicket = payload;
    },
    setAnswer: (state, payload) => {
      state.selectedTicket.answers.unshift(payload);
    },
    setShowTicketModal: (state, payload) => {
      state.showTicketModal = payload;
    },
    hydrateTicketList: (state, ticket) => {
      if (Array.isArray(state.ticketList.data)) {
        state.ticketList.data = hydrateTicket(ticket, state.ticketList.data);
      }
    },
    addNewAnswer: (state, answer) => {
      if (Array.isArray(state.ticketList.data)) {
        state.ticketList.data = addNewAnswer(answer, state.ticketList.data);
      }
    },
    setHasNewSupportMessages: (state, payload) => {
      state.hasNewSupportMessages = payload;
    },
    setPreviousTicketId: (state, payload) => {
      state.previousTicketId = payload;
    },
  },
  actions: {
    async addTicket({ commit }, payload) {
      try {
        const res = await SupportApi.saveSupportTicket(payload);
        commit('addTicket', res.data.support);
        return res;
      } catch (e) {
        console.log(e);
        return Promise.reject(e);
      }
    },
    async fetchTickets({ state, getters }, pageUrl) {
      const payload = {
        ...state.filters,
      };

      if (!getters.userIsAdmin) {
        payload.vetId = getters.userId;
        payload.countryId = getters.userCountry;
      }
      try {
        const res = await SupportApi.getSupportTickets(pageUrl, payload);
        return res;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchTicket({ commit }, id) {
      try {
        const { data } = await SupportApi.getSupportTicket(id);
        commit('setSelectedTicket', data.support);
        return data.support;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    sendAnswer({ commit, state }, { id, data }) {
      return SupportApi.sendAnswer(id, data)
        .then(res => {
          commit('setAnswer', res.data.support_message);
          const { answers } = state.selectedTicket;
          const { ticket } = res.data;
          ticket.answers = answers;
          commit('hydrateTicketList', ticket);
          return res;
        })
        .catch(e => {
          Promise.reject(e);
        });
    },
    updateAnswer(_, { ticketId, answerId, data }) {
      return SupportApi.updateAnswer(ticketId, answerId, data)
        .then(res => {
          return res;
        })
        .catch(e => {
          Promise.reject(e);
        });
    },
    updateSupportTicket({ commit, state }, { id, data }) {
      return SupportApi.updateSupportTicket(id, data)
        .then(res => {
          const { answers } = state.selectedTicket;
          const ticket = res.data.support;
          commit('hydrateTicketList', ticket);
          ticket.answers = answers;
          commit('setSelectedTicket', ticket);
          return res;
        })
        .catch(e => {
          Promise.reject(e);
        });
    },
  },
};
