<template>
  <inline-svg 
    :src="require(`@/assets/svg/pictograms/${fileName}.svg`)" 
    :aria-label="fileName" 
    :class="[sizeClasses]"
  />
</template>

<script>
import InlineSvg from 'vue-inline-svg';
import { sizes, setSize } from './icon-sizes';

export default {
  components: {
    InlineSvg,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      validator(val) {
        return Object.keys(sizes).includes(val);
      },
      default: 'sm',
    },
  },
  computed: {
    fileName() {
      const capitalizeFirst = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      return this.icon
        .split('-')
        .map(string => capitalizeFirst(string))
        .join('-');
    },
    sizeClasses() {
      return setSize(this.size);
    },
  },
};
</script>
