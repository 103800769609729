// eslint-disable-next-line import/no-cycle
import { AxiosPromise } from 'axios';
import axios from '@/plugins/axios';

export default {
  createJournalTemplate(data: any): AxiosPromise {
    return axios.post('/api/journal-templates', data);
  },
  getAllJournalTemplates(filters: any): AxiosPromise {
    return axios.get('/api/journal-templates', { params: filters });
  },
  getJournalTemplates(pageUrl: string | null, payload: any): AxiosPromise {
    const endpoint = pageUrl || '/api/journal-templates';
    return axios.get(endpoint, { params: payload });
  },
  getJournalTemplate(id): AxiosPromise {
    return axios.get(`/api/journal-template/${id}`);
  },
  updateJournalTemplate(id: number, data: any): AxiosPromise {
    return axios.put(`/api/journal-template/${id}`, data);
  },
  deleteJournalTemplate(id: number): AxiosPromise {
    return axios.delete(`/api/journal-template/${id}`);
  },
};
