/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

import Vue from 'vue';

Vue.directive('focus', {
  inserted(el) {
    el.focus();
  },
});

Vue.directive('click-outside', {
  bind(el, binding) {
    el.__ClickOutSideHandler__ = event => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    setTimeout(() => {
      document.body.addEventListener('click', el.__ClickOutSideHandler__);
    }, 500);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.__ClickOutSideHandler__);
  },
});

Vue.directive('loading', (el, binding, oldValue) => {
  const old = oldValue.elm;
  if (binding.value) {
    el.style.opacity = 0.6;
    // instance.$mount()
    // el.appendChild(instance.$root.$el)
  } else {
    // const child = el.querySelector('#baseSpinner')
    // if(child) child.parentNode.removeChild(child)
    el.style.opacity = 1;
    el = old || el;
  }
});
