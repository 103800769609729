var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inline-flex w-full"},[_c('span',{staticClass:"relative block w-full"},[(_vm.disabled)?_c('div',{staticClass:"pointer-events-none absolute inset-0 select-none bg-white opacity-50"}):_vm._e(),_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isOpen || !_vm.searchable),expression:"!isOpen || !searchable"}],ref:"selectButton",staticClass:"flex w-full items-center justify-between space-x-4 text-left",class:[
        _vm.baseClasses,
        _vm.borderClasses,
        _vm.focusClasses,
        _vm.hoverClasses,
        _vm.isOpen ? 'border-azure-25 bg-gray-100 text-gray-600' : '',
        _vm.disabled ? 'cursor-default' : '' ],attrs:{"type":"button","aria-roledescription":"Dropdown button","tabindex":"1","disabled":_vm.loading || _vm.disabled},on:{"click":_vm.toggleOpen,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.isOpen = false}}},[_c('span',{class:[_vm.labelSizes]},[_vm._v(_vm._s(_vm.selectedItem ? _vm.selectedItem[_vm.labelAttr] : _vm.placeholder))]),_c('fv-icon',{staticClass:"text-gray-600",attrs:{"icon":"chevron-down"}})],1),_c('input',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen && _vm.searchable),expression:"isOpen && searchable"},{name:"model",rawName:"v-model",value:(_vm.searchStr),expression:"searchStr"}],ref:"searchInput",staticClass:"z-50 flex w-full items-center justify-between text-left",class:[
        _vm.baseClasses,
        _vm.borderClasses,
        _vm.focusClasses,
        _vm.hoverClasses,
        _vm.labelSizes,
        _vm.isOpen ? 'border-azure-25 bg-gray-100 text-gray-600' : '' ],attrs:{"placeholder":"Search..","tabindex":"1","type":"search","name":_vm.name},domProps:{"value":(_vm.searchStr)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.isOpen = false},"input":function($event){if($event.target.composing){ return; }_vm.searchStr=$event.target.value}}},'input',_vm.$attrs,false)),_c('transition',{attrs:{"enter-class":"transition ease-out duration-100","enter-active-class":"transform opacity-0 scale-95","enter-to-class":"transform opacity-100 scale-100","leave-class":"transition ease-in duration-75","leave-active-class":"transform opacity-100 scale-100","leave-to-class":"transform opacity-0 scale-95"}}),_c('div',{staticClass:"relative"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"absolute top-0 right-0 z-50 w-full overflow-auto rounded border bg-white py-2 shadow-sm",style:({ maxHeight: _vm.height })},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.filterOptions.length),expression:"filterOptions.length"}],staticClass:"flex flex-col",attrs:{"role":"menu","aria-orientation":"vertical","aria-labelledby":"options-menu"}},_vm._l((_vm.filterOptions),function(opt,i){return _c('li',{key:i,on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.isOpen = false}}},[_c('button',{staticClass:"focus:outline-none w-full py-2 px-2 text-left hover:bg-gray-100 focus:bg-gray-100",class:[
                opt[_vm.valueAttr] === _vm.value ? 'font-semibold text-gray-900' : '' ],attrs:{"type":"button","tabindex":i + 1,"value":opt[_vm.valueAttr]},on:{"click":function($event){return _vm.selectItem(opt[_vm.valueAttr])}}},[_c('span',{class:[_vm.textSizes]},[_vm._v(_vm._s(opt[_vm.labelAttr]))])])])}),0),(!_vm.filterOptions.length)?_c('p',{staticClass:"py-2 px-2 text-left text-gray-700",class:[_vm.textSizes]},[_vm._v(" No options matched your search query. ")]):_vm._e()])]),_c('spinner-overlay',{attrs:{"color":"light","loading":_vm.loading}})],1),(_vm.isOpen)?_c('div',{staticClass:"absolute inset-0 z-10",on:{"click":function($event){_vm.isOpen = false}}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }