import { AxiosPromise } from 'axios';
import { AppointmentCreatePayload } from '@/types';
import axios from '@/plugins/axios';

export default {
  search(countryId: number, searchStr: string): AxiosPromise {
    return axios.get('/api/admin/appointments', {
      params: { searchStr, countryId },
    });
  },
  create(payload: AppointmentCreatePayload): AxiosPromise {
    return axios.post('api/internal-booking', payload);
  },
  updateBookingMetaData(appointmentId: number, payload: {}): AxiosPromise {
    return axios.put(`/api/booking/${appointmentId}/booking-metadata`, payload);
  },
};
